import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from './muiTheme'
import { eventLogger, keycloak } from './services/keycloak.service'
import { BrowserRouter } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import './i18next'

export const keycloakInitConfig = {
  onLoad: 'login-required',
  checkLoginIframe: false,
}

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger(store)} initOptions={keycloakInitConfig}>
      <Provider store={store}>
        <BrowserRouter basename={'/itrader'}>
          <CssBaseline />
          <ThemeProvider theme={muiTheme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
