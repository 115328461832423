import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ColoredIconButton } from '../common/ColoredIconButton'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { useDispatch } from 'react-redux'
import { showPortfolioDetailAction } from '../../redux/actions/userActions'
import { Client } from '../../redux/reducers/clients'
import { useSelector } from '../../redux/hooks/useSelector'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
  infoWindowTopBar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  boldText: {
    fontWeight: 'bold',
  },
  titleFont: {
    fontSize: '18px',
  },
  separator: {
    flexGrow: 1,
  },
  detailButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))

export const PortfolioEnhancedDetailTopBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const client = useSelector(state => state.clients.client)

  const onMinimizeButtonClick = () => {
    dispatch(showPortfolioDetailAction(false))
  }

  return (
    <div className={classes.infoWindowTopBar}>
      <span className={classes.marginLeft}>Detail portfolia</span>
      <span className={`${classes.marginLeft} ${classes.boldText} ${classes.titleFont}`}>
        {client.firstName} {client.surname}
      </span>
      <span className={classes.separator} />
      <Button
        classes={{ root: classes.detailButton }}
        size="small"
        startIcon={<ArrowBackIcon />}
        onClick={onMinimizeButtonClick}
      >
        Přehled portfolia
      </Button>
    </div>
  )
}
