import {
  ADD_ORDER,
  EXPORT_ORDERS,
  GET_FILTERED_SECURITIES,
  GET_ORDER,
  GET_ORDERS,
  GET_ORDERS_BY_STATE_COUNT,
  GET_ORDER_TABLE_ROWS,
  GET_SECURITIES,
  GET_SECURITY,
  UPDATE_ORDER,
} from '../actionTypes'
import { OrdersByStateCount, OrderTableRow } from '../reducers/orders'
import { Order } from '../reducers/orders'
import { Security } from '../reducers/securities'

//TODO separate/rename orders from instruments

export const getSecuritiesAction = (securities: Security[]) => ({ type: GET_SECURITIES, payload: securities })
export const getSecurityAction = (security: Security | undefined) => ({ type: GET_SECURITY, payload: security })
export const getFilteredSecuritiesAction = (securities: Security[]) => ({
  type: GET_FILTERED_SECURITIES,
  payload: securities,
})

export const getOrderAction = (order: Order) => ({ type: GET_ORDER, payload: order })
export const getOrdersAction = (orders: Order[]) => ({ type: GET_ORDERS, payload: orders })
export const addOrderAction = (order: Order) => ({ type: ADD_ORDER, payload: order })
export const updateOrderAction = (order: Order) => ({ type: UPDATE_ORDER, payload: order })
export const getOrdersByStateCountAction = (ordersByStateCounters: OrdersByStateCount[]) => ({
  type: GET_ORDERS_BY_STATE_COUNT,
  payload: ordersByStateCounters,
})
export const getOrderTableRowsAction = (orderTableRows: OrderTableRow[]) => ({
  type: GET_ORDER_TABLE_ROWS,
  payload: orderTableRows,
})
