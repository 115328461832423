import React, { useEffect } from 'react'
import { AppBar, Badge, CircularProgress, makeStyles, Toolbar, Typography } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { TransparentIconButton } from './TransparentIconButton'
import IfLogoPh from '../../assets/IfLogoPh.png'
import DescriptionIcon from '@material-ui/icons/Description'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { keycloak, logout } from '../../services/keycloak.service'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../redux/actions/keycloakActions'
import { baseURL } from '../../services/http.service'
import PublishIcon from '@material-ui/icons/Publish'
import { openDialog } from '../../redux/actions/dialogActions'
import { FILE_IMPORT_DIALOG_ID } from '../../constants/dialogConstants'
import { ORDER_STATE_NEW } from '../../constants/codeLists'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  userLogo: {
    height: '30px',
  },
  connectionStatus: {},
  activeUser: {
    marginRight: theme.spacing(8),
  },
  separator: {
    flexGrow: 1,
  },
  badge: {
    marginRight: theme.spacing(8),
  },
}))

export const TopBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getOrdersByStateCount, exportOrders } = useInstrumentApi()
  const ordersByStateCount = useSelector(state => state.orders.ordersByStateCount)
  const user = useSelector(state => state.auth.user)
  const loaders = useSelector(state => state.loading.isLoading)
  const isExportLoading = loaders.find(e => e === 'exportFile')

  useEffect(() => {
    getOrdersByStateCount()
  }, [])

  return (
    <AppBar color="secondary" position="static" className={classes.root}>
      <Toolbar>
        <img alt="" className={classes.userLogo} src={IfLogoPh} />
        <Typography className={classes.separator} />
        {isExportLoading ? (
          <CircularProgress />
        ) : (
          <TransparentIconButton
            onClick={exportOrders}
            icon={
              <Badge
                badgeContent={ordersByStateCount.find(count => count.state === ORDER_STATE_NEW)?.count || 0}
                color="primary"
              >
                <DescriptionIcon />
              </Badge>
            }
          />
        )}
        <TransparentIconButton
          icon={<PublishIcon />}
          onClick={() => {
            dispatch(openDialog(FILE_IMPORT_DIALOG_ID))
          }}
        />
        <Typography className={classes.activeUser}>{user?.username}</Typography>
        <TransparentIconButton onClick={logout} icon={<ExitToAppIcon />} />
      </Toolbar>
    </AppBar>
  )
}
