import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Client } from '../redux/reducers/clients'

const useStyles: any = makeStyles(theme => ({
  riskProfile1: {
    backgroundColor: theme.palette.success.dark,
  },
  riskProfile2: {
    backgroundColor: theme.palette.success.main,
  },
  riskProfile3: {
    backgroundColor: theme.palette.success.light,
  },
  riskProfile4: {
    backgroundColor: theme.palette.warning.light,
  },
  riskProfile5: {
    backgroundColor: theme.palette.warning.main,
  },
  riskProfile6: {
    backgroundColor: theme.palette.error.light,
  },
  riskProfile7: {
    backgroundColor: theme.palette.error.main,
  },
}))

export const useRiskProfileColor = (client?: Client) => useStyles()[`riskProfile${(client && client.riskProfile) || 1}`]
