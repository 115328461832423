import { createMuiTheme } from '@material-ui/core'

export const themeObject = {
  palette: {
    primary: {
      main: '#4562ab',
      dark: 'rgb(48, 68, 119)',
      mainWhite: '#fff',
      contrastMainWhite: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#fff',
      darklight: '#E6D815',
      mustsee: '#C6BDE9',
    },
    background: {
      default: '#ecf5fc',
    },
    text: {},
    error: {
      main: 'rgb(255, 87, 51)',
    },
    warning: {
      main: 'rgb(255, 195, 0)',
    },
    buy: {
      main: '#4562ab',
    },
  },
  typography: {
    fontSize: 12,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    fontWeightLight: 200,
    fontFamily:
      'Roboto,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
}

export const muiTheme = createMuiTheme(themeObject)
