import { combineReducers } from 'redux'
import { auth } from './auth'
import { clients } from './clients'
import { dialogs } from './dialogs'
import { filters } from './filters'
import { detailPortfolioState } from './portfolio'
import { securities } from './securities'
import { orders } from './orders'
import { currency } from './currency'
import { useractions } from './useractions'
import { loading } from './loading'

export const rootReducer = combineReducers({
  auth,
  clients,
  dialogs,
  filters,
  detailPortfolioState,
  securities,
  orders,
  currency,
  useractions,
  loading,
})

export type RootState = ReturnType<typeof rootReducer>
