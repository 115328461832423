import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { useSelector } from '../../redux/hooks/useSelector'
import { Position } from '../../redux/reducers/portfolio'

// TODO Styles
const useStyles = (color: string) =>
  makeStyles(theme => ({
    detailTableContainer: {
      display: 'flex',
      width: '100%',
      borderLeft: `5px solid ${color}`,
      flexDirection: 'column',
      marginRight: theme.spacing(0.5),
    },
    detailTotal: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(0.5),
    },
    typeTotal: {
      display: 'flex',
    },
    tableRows: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tableFirstRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    tableSecondRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    textColor: {
      color: theme.palette.primary.dark,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    separatorLeftSmall: {
      marginLeft: theme.spacing(0.4),
    },
    separatorLeft: {
      marginLeft: theme.spacing(1),
    },
    separatorLeftLarge: {
      marginLeft: theme.spacing(2),
    },
    name: {
      display: 'inline-block',
    },
    portfolioCurrencyTotal: {
      textAlign: 'end',
    },
    tableRowButton: {
      height: '15px',
      width: '15px',
      alignSelf: 'flex-end',
    },
    buttonMargin: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fontSize: {
      fontSize: '14px',
    },
    fontSizeHeader: {
      fontSize: '14px',
    },
    fontSizeLegend: {
      fontSize: '12px',
    },
    tableRowContainer: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    buttonIcon: {
      height: '12px',
      width: '12px',
    },
    buttonContainer: {
      display: 'flex',
      width: '60px',
      height: '100%',
      justifyContent: 'center',
    },
    iconSize: {
      height: '10px',
      width: '15px',
      alignSelf: 'center',
    },
    separator: {
      flexGrow: 1,
    },
    textEnd: {
      textAlign: 'end',
    },
  }))

interface Props {
  portfolio: { positions: Array<Position>; percentage: string; total: number; name: string; color: string }
}

export const PortfolioEnhancedDetailLegend: React.FC<Props> = ({ portfolio }) => {
  const classes = useStyles(portfolio.color)()
  const rates = useSelector(state => state.currency.rates)
  const { t } = useTranslation()

  const exchangeRate = (currency: string, value: number) => {
    if (currency === 'CZK') {
      return value
    } else {
      const exRate = rates.find(r => r.currencyFrom === currency)
      const exchanged = (value * (exRate?.rate || 0)) / (exRate?.value || 1)
      return exchanged
    }
  }

  const sortedPositions = portfolio.positions.sort((a, b) => {
    return exchangeRate(b.instrument.currency, b.amount) - exchangeRate(a.instrument.currency, a.amount)
  })

  //TODO row components

  return (
    <div className={classes.detailTableContainer}>
      <div className={classes.detailTotal}>
        <div className={classes.typeTotal}>
          <span style={{ width: 35 }} className={`${classes.textColor} ${classes.fontSizeHeader} ${classes.textEnd}`}>
            {portfolio.percentage}%
          </span>
          <span style={{ width: 140 }} className={`${classes.textColor} ${classes.fontSizeHeader} ${classes.textEnd}`}>
            <NumberFormat
              value={Math.round((portfolio.total + Number.EPSILON) * 100) / 100}
              displayType="text"
              thousandSeparator=" "
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
            />
          </span>
          <span
            className={`${classes.textColor} ${classes.boldText} ${classes.separatorLeft} ${classes.fontSizeHeader} `}
          >
            {t(portfolio.name)}
          </span>
          <div className={classes.separator} />
        </div>
      </div>
    </div>
  )
}
