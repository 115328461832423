import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { ClientDetailOrderTableHeader } from './ClientDetailOrderTableHeader'
import { ClientDetailOrderTableRow } from './ClientDetailOrderTableRow'

const useStyles = makeStyles(theme => ({
  detailTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    overflow: 'auto',
  },
}))

export const ClientDetailOrderTable = () => {
  const classes = useStyles()
  const securities = useSelector(state => state.securities.securities)
  const { getOrders } = useInstrumentApi()
  const orders = useSelector(state => state.detailPortfolioState.portfolio.unfinishedOrders)

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <div className={classes.detailTableContainer}>
      <div>{/*} <OrderTableFilters /> {*/}</div>
      <div>
        <ClientDetailOrderTableHeader />
        {orders.map((order, index) => (
          <ClientDetailOrderTableRow key={index} securities={securities} order={order} />
        ))}
      </div>
    </div>
  )
}
