import { makeStyles } from '@material-ui/core'
import React from 'react'
import { usePortfolioTotals } from '../../hooks/usePortfolioTotals'
import { PortfolioDetailTable } from './PortfolioDetailTable'

const useStyles = makeStyles(theme => ({
  portfolioDetailContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    margin: 'auto 0',
  },
}))
export const PortfolioDetailTablelist = () => {
  const classes = useStyles()

  const { portFolioAssetsEnhanced } = usePortfolioTotals()

  const sortedArray = Object.entries(portFolioAssetsEnhanced).sort((a, b) => {
    return b[1].total - a[1].total
  })

  return (
    <div className={classes.portfolioDetailContent}>
      {sortedArray.map(([, asset], index) => (
        <PortfolioDetailTable key={index} portfolio={asset} />
      ))}
    </div>
  )
}
