import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { useSelector } from '../../redux/hooks/useSelector'
import { Position } from '../../redux/reducers/portfolio'

// TODO Styles
const useStyles = (color: string) =>
  makeStyles(theme => ({
    detailTableContainer: {
      display: 'flex',
      width: '98%',
      flexDirection: 'column',
    },
    detailTotal: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      borderBottom: `3px solid ${color}`,
    },
    typeTotal: {
      display: 'flex',
    },
    tableRows: {
      width: '100%',
    },
    tableLegend: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tableFirstRow: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    tableSecondRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    textColor: {
      color: theme.palette.primary.dark,
    },
    boldText: {
      fontWeight: 'bold',
    },
    separatorLeftSmall: {
      marginLeft: theme.spacing(0.4),
    },
    separatorLeft: {
      marginLeft: theme.spacing(1),
    },
    name: {
      display: 'inline-block',
    },
    portfolioCurrencyTotal: {
      textAlign: 'end',
    },
    tableRowButton: {
      height: '15px',
      width: '15px',
      alignSelf: 'flex-end',
    },
    buttonMargin: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fontSize: {
      fontSize: '13px',
    },
    fontSizeHeader: {
      fontSize: '16px',
    },
    fontSizeLegend: {
      fontSize: '12px',
    },
    tableRowContainer: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    buttonIcon: {
      height: '12px',
      width: '12px',
    },
    buttonContainer: {
      display: 'flex',
      width: '60px',
      height: '100%',
      justifyContent: 'center',
    },
    iconSize: {
      height: '10px',
      width: '15px',
      alignSelf: 'center',
    },
    separator: {
      flexGrow: 1,
    },
    textEnd: {
      textAlign: 'end',
    },
  }))

interface Props {
  portfolio: { positions: Array<Position>; percentage: string; total: number; name: string; color: string }
}

export const PortfolioDetailTable: React.FC<Props> = ({ portfolio }) => {
  const classes = useStyles(portfolio.color)()
  const rates = useSelector(state => state.currency.rates)
  const { t } = useTranslation()

  const exchangeRate = (currency: string, value: number) => {
    if (currency === 'CZK') {
      return value
    } else {
      const exRate = rates.find(r => r.currencyFrom === currency)
      const exchanged = (value * (exRate?.rate || 0)) / (exRate?.value || 1)
      return exchanged
    }
  }

  const sortedPositions = portfolio.positions.sort((a, b) => {
    return exchangeRate(b.instrument.currency, b.amount) - exchangeRate(a.instrument.currency, a.amount)
  })

  //TODO row components

  return (
    <div className={classes.detailTableContainer}>
      <div className={classes.detailTotal}>
        <div className={classes.typeTotal}>
          <span className={`${classes.textColor} ${classes.fontSizeHeader}`}>
            <NumberFormat
              value={Math.round((Number(portfolio.percentage) + Number.EPSILON) * 100) / 100}
              decimalSeparator=","
              displayType="text"
              thousandSeparator=" "
            />
            %
          </span>
          <span
            className={`${classes.textColor} ${classes.boldText} ${classes.separatorLeft} ${classes.fontSizeHeader}`}
          >
            {t(portfolio.name)}
          </span>
          <div className={classes.separator} />
          <span className={`${classes.textColor} ${classes.separatorLeft} ${classes.fontSizeHeader}`}>
            <NumberFormat
              value={Math.round((portfolio.total + Number.EPSILON) * 100) / 100}
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
              displayType="text"
              thousandSeparator=" "
            />{' '}
            CZK
          </span>
        </div>
      </div>
      <div className={classes.tableLegend}>
        <Typography style={{ width: 70 }} className={`${classes.textColor} ${classes.fontSizeLegend}`}>
          Podíl
        </Typography>
        <Typography
          style={{ width: 400 }}
          className={`${classes.name} ${classes.textColor} ${classes.fontSizeLegend} ${classes.separatorLeftSmall}`}
        >
          Název
        </Typography>
        <Typography style={{ width: 110 }} className={`${classes.textColor} ${classes.fontSizeLegend}`}>
          ISIN
        </Typography>
        <Typography
          style={{ width: 100 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Typ instrumentu
        </Typography>

        <Typography
          style={{ width: 70 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Počet kusů
        </Typography>
        <div className={classes.separator} />
        <Typography
          style={{ width: 140 }}
          className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSizeLegend}`}
        >
          Objem
        </Typography>
        <Typography
          style={{ width: 30 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.separatorLeftSmall}`}
        ></Typography>
        <Typography
          style={{ width: 140 }}
          className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSizeLegend}`}
        >
          v CZK
        </Typography>
      </div>
      {sortedPositions.map(p => (
        <div key={p.positionId} className={classes.tableRowContainer}>
          <div className={classes.tableRows}>
            <div className={classes.tableFirstRow}>
              <Typography
                style={{ width: 70 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                <NumberFormat
                  value={
                    Math.round(
                      ((100 * exchangeRate(p.instrument.currency, p.amount)) / portfolio.total + Number.EPSILON) * 100
                    ) / 100
                  }
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  displayType="text"
                  thousandSeparator=" "
                />
                %
              </Typography>
              <Typography
                style={{ width: 400 }}
                className={`${classes.name} ${classes.textColor} ${classes.boldText} ${classes.fontSize} ${classes.separatorLeftSmall}`}
              >
                {p.instrument.name}
              </Typography>
              <Typography style={{ width: 110 }} className={`${classes.textColor} ${classes.fontSize}`}>
                {p.instrument.instrumentType !== 'ACCOUNT' && p.instrument.isin}
              </Typography>
              <Typography
                style={{ width: 100 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {t(p.instrument.instrumentType)}
              </Typography>

              <Typography
                style={{ width: 70 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.instrumentType !== 'ACCOUNT' && p.quantity}
              </Typography>
              <div className={classes.separator} />
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSize} ${classes.boldText}`}
              >
                <NumberFormat
                  value={Math.round((p.amount + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />
              </Typography>
              <Typography
                style={{ width: 30 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.separatorLeftSmall} ${classes.boldText}`}
              >
                {p.instrument.currency}
              </Typography>
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSize}`}
              >
                <NumberFormat
                  value={Math.round((exchangeRate(p.instrument.currency, p.amount) + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
