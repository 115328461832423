import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'

const useStyles = makeStyles(theme => ({
  equity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}))

interface Props {
  className?: string
  securityType: string
}

export const InstrumentIcon: React.FC<Props> = ({ className, securityType }) => {
  const classes = useStyles()
  const instrumentIconType = useInstrumentIconType(securityType)

  return <div className={classNames(classes.equity, className, instrumentIconType)}>{securityType}</div>
}
