import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CURRENCY,
  INSTRUMENT_NAME,
  ORDER_AMOUNT,
  ORDER_DIRECTION,
  ORDER_STATE,
  ORDER,
  ORDER_QUANTITY
} from '../../constants/textConstants'

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  boldText: {
    fontWeight: 'bold',
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(0.4),
  },
  separator: {
    flexGrow: 1,
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
  separatorLeftHuge: {
    marginLeft: theme.spacing(5),
  },
  separatorLeftLarge: {
    marginLeft: theme.spacing(3),
  },
  borderColorShare: {
    borderColor: theme.palette.error.main,
  },
  borderColorFcMarket: {
    borderColor: theme.palette.success.main,
  },
  borderColorBond: {
    borderColor: theme.palette.primary.main,
  },
  borderColorOther: {
    borderColor: theme.palette.grey[600],
  },
  textColorGreen: {
    color: theme.palette.success.main,
  },
  iconSize: {
    height: '18px',
    width: '30px',
    lineHeight: '15px',
    fontSize: '8px',
  },
  textEnd: {
    textAlign: 'end',
  },
  editButton: {
    marginLeft: '12px',
    height: '18px',
    width: '18px',
  },
}))

export const ClientDetailOrderTableHeader = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.tableRow}>
      <Typography style={{ width: 100 }} className={`${classes.textColor} ${classes.separatorLeft} `}>
        {t(ORDER)}
      </Typography>
      <div className={`${classes.iconSize} ${classes.separatorLeft}`} />
      <Typography style={{ width: 300 }} className={`${classes.textColor} ${classes.separatorLeft} `}>
        {t(INSTRUMENT_NAME)}
      </Typography>
      <Typography
        style={{ width: 100, textAlign: 'left' }}
        className={`${classes.textColor}  ${classes.separatorLeftHuge} ${classes.textEnd}`}
      >
        {t(ORDER_STATE)}
      </Typography>
      <Typography
        style={{ width: 100, textAlign: 'left' }}
        className={`${classes.textColor}  ${classes.separatorLeftHuge} ${classes.textEnd}`}
      >
        {t(ORDER_DIRECTION)}
      </Typography>
      <Typography
        style={{ width: 100 }}
        className={`${classes.textColor}  ${classes.separatorLeftLarge} ${classes.textEnd}`}
      >
        {t(ORDER_QUANTITY)}
      </Typography>
      <Typography
        style={{ width: 100 }}
        className={`${classes.textColor}  ${classes.separatorLeftLarge} ${classes.textEnd}`}
      >
        {t(ORDER_AMOUNT)}
      </Typography>
      <Typography
        style={{ width: 100, textAlign: 'left' }}
        className={`${classes.textColor}  ${classes.separatorLeftLarge} ${classes.textEnd}`}
      >
        {t(CURRENCY)}
      </Typography>
      <Typography className={classes.editButton}></Typography>
    </div>
  )
}
