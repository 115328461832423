import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  clientContainer: {
    height: '100px',
    width: '550px',
    display: 'flex',
    flexDirection: 'row',
  },
  commodityContainer: {
    height: '100px',
    width: '550px',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  selectionContainer: {
    width: '550px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  currencyTotal: {
    height: '50px',
    width: '550px',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  clientPersonalInfoContainer: {
    width: '100%',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  clientPortfolioContainer: {
    height: '100%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  topContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconSize: {
    height: '25px',
    width: '45px',
    lineHeight: '25px',
    fontSize: '12px',
  },
  position: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  topLineText: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  topLineIsin: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topLineSeparator: {
    flexGrow: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    width: '100%',
    height: '50%',
    alignItems: 'flex-end',
  },
  securityInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.success.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  clientIcon: {
    height: '40px',
    width: '40px',
  },
  clientCredentials: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  clientPhone: {
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    width: '100%',
  },
  totalsContainer: {
    height: '100%',
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  isin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  halfWidth: {
    height: '100%',
    width: '50%',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textWhite: {
    color: theme.palette.secondary.main,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  textSeparatorLeft: {
    marginLeft: theme.spacing(1),
  },
  alignSelfToEnd: {
    alignSelf: 'flex-end',
  },
  flexGrow: {
    flexGrow: 1,
  },
  textToEnd: {
    textAlign: 'end',
  },
}))
