import { makeStyles } from '@material-ui/core'
import { Security } from '../redux/reducers/securities'

const useStyles: any = makeStyles(theme => ({
  iconTypeEQTY: {
    backgroundColor: theme.palette.error.light,
  },
  iconTypeB: {
    backgroundColor: theme.palette.primary.light,
  },
  iconTypeBOND: {
    backgroundColor: theme.palette.primary.light,
  },
  iconTypeE: {
    backgroundColor: theme.palette.error.light,
  },
  iconTypeETF: {
    backgroundColor: theme.palette.error.light,
  },
  iconTypeMM: {
    backgroundColor: theme.palette.success.main,
  },
  iconTypeOTHER: {
    backgroundColor: theme.palette.grey[400],
  },
}))

export const useInstrumentIconType = (securityType: string | null) => useStyles()[`iconType${securityType || 'E'}`]
