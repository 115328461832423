import { makeStyles } from '@material-ui/core'
import React from 'react'
import { customThemeObject } from '../customThemeObject'

const useStyles: any = makeStyles(theme => ({
  buy: {
    backgroundColor: customThemeObject.palette.buy.main,
  },
  sell: {
    backgroundColor: customThemeObject.palette.sell.main,
  },
  grey: {
    backgroundColor: theme.palette.grey[50],
  },
  white: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const useOrderTableRowColor = () => {
  const classes = useStyles()

  return (direction: string, index: number) => {
    if (direction === 'OD_SELL') {
      return classes.sell
    } else if (index % 2 === 0) {
      return classes.white
    }
    return classes.grey
  }
}
