import { makeStyles, Paper, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '150px',
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  middleText: {
    alignSelf: 'center',
    fontSize: theme.typography.fontSize * 3,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  bottomText: {
    position: 'absolute',
    bottom: '5px',
  },
}))

interface Props {
  middleText: React.ReactNode
  bottomText: React.ReactNode
  className?: string
  onTileClick?: React.MouseEventHandler<HTMLDivElement>
}

export const ActiveOrdersTile: React.FC<Props> = ({ middleText, bottomText, className, onTileClick }) => {
  const classes = useStyles()

  return (
    <Paper onClick={onTileClick} className={classes.root}>
      <Typography className={classNames(classes.middleText, className)}>{middleText}</Typography>
      <Typography className={classes.bottomText}>{bottomText}</Typography>
    </Paper>
  )
}
