import { makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from '../../redux/hooks/useSelector'
import { Position } from '../../redux/reducers/portfolio'
import { ColoredIconButton } from '../common/ColoredIconButton'
import { InstrumentIcon } from '../common/InstrumentIcon'
import AddIcon from '@material-ui/icons/Add'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useZebraStyle } from '../../hooks/useZebraStyle'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RemoveIcon from '@material-ui/icons/Remove'
import { useProfitLossSign } from '../../hooks/useProfitLossSign'
import { useTranslation } from 'react-i18next'

// TODO Styles
const useStyles = (color: string) =>
  makeStyles(theme => ({
    detailTableContainer: {
      display: 'flex',
      width: '100%',

      flexDirection: 'column',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
    detailTotal: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderBottom: `3px solid ${color}`,
    },
    typeTotal: {
      display: 'flex',
    },
    tableRows: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tableLegend: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tableFirstRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(0.5),
    },
    tableSecondRow: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    textColor: {
      color: theme.palette.primary.dark,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    separatorLeftSmall: {
      marginLeft: theme.spacing(0.4),
    },
    separatorLeft: {
      marginLeft: theme.spacing(1),
    },
    name: {
      display: 'inline-block',
    },
    portfolioCurrencyTotal: {
      textAlign: 'end',
    },
    tableRowButton: {
      height: '15px',
      width: '15px',
      alignSelf: 'flex-end',
    },
    buttonMargin: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    fontSize: {
      fontSize: '14px',
    },
    fontSizeHeader: {
      fontSize: '16px',
    },
    fontSizeLegend: {
      fontSize: '14px',
    },
    tableRowContainer: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    buttonIcon: {
      height: '20px',
      width: '20px',
    },
    buttonContainer: {
      display: 'flex',
      width: '60px',
      height: '100%',
      justifyContent: 'center',
    },
    iconSize: {
      height: '18px',
      width: '30px',
      lineHeight: '15px',
      fontSize: '8px',
      marginTop: theme.spacing(0.2),
    },
    separator: {
      flexGrow: 1,
    },
    instrumentTableButton: {
      height: '28px',
      width: '28px',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1.5),
    },
    instrumentTableButtonMock: {
      width: '28px',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    textEnd: {
      textAlign: 'end',
    },
    iconMock: {
      width: '30px',
    },
    fontWeightRegular: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }))

interface Props {
  portfolio: { positions: Array<Position>; percentage: string; total: number; name: string; color: string }
}

export const PortfolioEnhancedDetailRow: React.FC<Props> = ({ portfolio }) => {
  const classes = useStyles(portfolio.color)()
  const rates = useSelector(state => state.currency.rates)
  const dispatch = useDispatch()
  const { getSecurity } = useInstrumentApi()
  const getBackgroundColor = useZebraStyle()
  const getProfitLossSign = useProfitLossSign()
  const { t } = useTranslation()

  const exchangeRate = (currency: string, value: number) => {
    if (currency === 'CZK') {
      return value
    } else {
      const exRate = rates.find(r => r.currencyFrom === currency)
      const exchanged = (value * (exRate?.rate || 0)) / (exRate?.value || 1)
      return exchanged
    }
  }

  const sortedPositions = portfolio.positions.sort((a, b) => {
    return exchangeRate(b.instrument.currency, b.amount) - exchangeRate(a.instrument.currency, a.amount)
  })

  //TODO row components

  return (
    <div className={classes.detailTableContainer}>
      <div className={classes.detailTotal}>
        <div className={classes.typeTotal}>
          <span className={`${classes.textColor} ${classes.fontSizeHeader}`}>{portfolio.percentage}%</span>
          <span
            className={`${classes.textColor} ${classes.boldText} ${classes.separatorLeft} ${classes.fontSizeHeader}`}
          >
            {t(portfolio.name)}
          </span>
          <div className={classes.separator} />
          <span className={`${classes.textColor} ${classes.separatorLeft} ${classes.fontSizeHeader}`}>
            <NumberFormat
              value={Math.round((portfolio.total + Number.EPSILON) * 100) / 100}
              displayType="text"
              thousandSeparator=" "
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
            />{' '}
            CZK
          </span>
        </div>
      </div>

      <div className={classes.tableLegend}>
        <span className={classes.iconMock} />
        <Typography
          style={{ width: 400 }}
          className={`${classes.name} ${classes.textColor} ${classes.fontSizeLegend} ${classes.separatorLeftSmall}`}
        >
          Název
        </Typography>
        <Typography
          style={{ width: 110 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Typ instrumentu
        </Typography>

        <Typography
          style={{ width: 90 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Počet kusů
        </Typography>
        <Typography
          style={{ width: 140 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Pořizovací cena
        </Typography>
        <Typography
          style={{ width: 140 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Poslední cena
        </Typography>
        <Typography
          style={{ width: 130 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Zisk/ztráta
        </Typography>
        <Typography
          style={{ width: 180 }}
          className={`${classes.textColor} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Absolutní zisk/ztráta
        </Typography>
        <div className={classes.separator} />
        <Typography
          style={{ width: 140 }}
          className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSizeLegend} ${classes.textEnd}`}
        >
          Objem
        </Typography>
        <div className={`${classes.instrumentTableButtonMock} ${classes.separatorLeft}`} />
      </div>
      {sortedPositions.map((p, index: any) => (
        <div className={`${classes.tableRowContainer} ${getBackgroundColor(index)}`}>
          <div className={classes.tableRows}>
            <div className={classes.tableFirstRow}>
              <InstrumentIcon securityType={p.instrument.assetClass} className={classes.iconSize} />
              <Typography
                style={{ width: 400 }}
                className={`${classes.name} ${classes.textColor} ${classes.boldText} ${classes.fontSize} ${classes.separatorLeftSmall}`}
              >
                {p.instrument.name}
              </Typography>
              <Typography
                style={{ width: 110 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {t(p.instrument.instrumentType)}
              </Typography>
              <Typography
                style={{ width: 90 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.assetClass !== 'MM' && p.quantity}
              </Typography>
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.assetClass !== 'MM' && (
                  <NumberFormat
                    value={Math.round((p.averagePurchasePrice + Number.EPSILON) * 100) / 100}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                )}{' '}
                {p.instrument.assetClass !== 'MM' && p.instrument.currency}
              </Typography>
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.assetClass !== 'MM' && (
                  <NumberFormat
                    value={Math.round((p.lastPrice + Number.EPSILON) * 100) / 100}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                )}{' '}
                {p.instrument.assetClass !== 'MM' && p.instrument.currency}
              </Typography>
              <Typography
                style={{ width: 50, textAlign: 'end' }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {getProfitLossSign(p.profitLossPercentage ? p.profitLossPercentage : 0)}
              </Typography>
              <Typography
                style={{ width: 80 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.profitLossPercentage ? (
                  <NumberFormat
                    value={Math.abs(Math.round((p.profitLossPercentage + Number.EPSILON) * 100) / 100)}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                ) : (
                  ''
                )}
                {p.profitLossPercentage ? '%' : ''}
              </Typography>
              <Typography
                style={{ width: 180 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                <NumberFormat
                  value={Math.round((p.profitLoss + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                {p.instrument.currency}
              </Typography>
              <div className={classes.separator} />
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSize} ${classes.textEnd} ${classes.boldText}`}
              >
                <NumberFormat
                  value={Math.round((p.amount + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                {p.instrument.currency}
              </Typography>
            </div>
            <div className={classes.tableSecondRow}>
              <span className={classes.iconSize} />
              <Typography
                style={{ width: 600 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.separatorLeft} ${classes.fontWeightRegular}`}
              >
                {p.instrument.isin}
              </Typography>
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.assetClass !== 'MM' && (
                  <NumberFormat
                    value={
                      Math.round((exchangeRate(p.instrument.currency, p.averagePurchasePrice) + Number.EPSILON) * 100) /
                      100
                    }
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                )}{' '}
                {p.instrument.assetClass !== 'MM' && 'CZK'}
              </Typography>
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                {p.instrument.assetClass !== 'MM' && (
                  <NumberFormat
                    value={Math.round((exchangeRate(p.instrument.currency, p.lastPrice) + Number.EPSILON) * 100) / 100}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                  />
                )}{' '}
                {p.instrument.assetClass !== 'MM' && 'CZK'}
              </Typography>
              <Typography
                style={{ width: 130 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              ></Typography>
              <Typography
                style={{ width: 180 }}
                className={`${classes.textColor} ${classes.fontSize} ${classes.textEnd}`}
              >
                <NumberFormat
                  value={Math.round((exchangeRate(p.instrument.currency, p.profitLoss) + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                CZK
              </Typography>
              <div className={classes.separator} />
              <Typography
                style={{ width: 140 }}
                className={`${classes.textColor} ${classes.portfolioCurrencyTotal} ${classes.fontSize} `}
              >
                <NumberFormat
                  value={Math.round((exchangeRate(p.instrument.currency, p.amount) + Number.EPSILON) * 100) / 100}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                CZK
              </Typography>
            </div>
          </div>
          {p.instrument.assetClass !== 'MM' ? (
            <ColoredIconButton
              className={`${classes.instrumentTableButton} ${classes.separatorLeft}`}
              size="small"
              icon={<AddIcon fontSize="small" className={classes.buttonIcon} />}
              onClick={() => {
                dispatch(openDialog(ORDER_INPUT_DIALOG_ID))
                getSecurity(p.instrument.instrumentId)
              }}
            />
          ) : (
            <div className={`${classes.instrumentTableButton} ${classes.separatorLeft}`} />
          )}
        </div>
      ))}
    </div>
  )
}
