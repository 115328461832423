import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { InferencePriority } from 'typescript'
import { useExchangeRate } from '../../hooks/useExchangeRate'
import { getClientAccountTotalAction } from '../../redux/actions/clientActions'
import { useSelector } from '../../redux/hooks/useSelector'
import { Client } from '../../redux/reducers/clients'
import { Position } from '../../redux/reducers/portfolio'

const useStyles = makeStyles(theme => ({
  fontSizeTotal: {
    fontSize: '14px',
  },
  PortTotal: {
    fontSize: '16px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  flexDisplay: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  rowDisplay: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  separator: {
    flexGrow: 1,
  },
  marginLeftSmall: {
    marginLeft: theme.spacing(0.5),
  },
  totalsContainer: {
    height: '100%',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const PortfolioEnhancedDetailTotals: React.FC<Props> = ({}) => {
  const classes = useStyles()
  const client: Client = useSelector(state => state.clients.client)
  const portfolio = useSelector(state => state.detailPortfolioState.portfolio)
  const rates = useSelector(state => state.currency.rates)
  const exchangeRate = useExchangeRate()

  useEffect(() => {
    total()
  })

  const assetTotal = () => {
    let total = 0
    portfolio.positions.map(p => {
      if (p.instrument.instrumentType !== 'ACCOUNT') {
        total += exchangeRate(p.instrument.currency, client.portfolios[0].currency, p.amount, rates)
      }
    })
    return total
  }

  const accountTotal = () => {
    let total = 0
    portfolio.positions.map(p => {
      if (p.instrument.instrumentType === 'ACCOUNT') {
        total += exchangeRate(p.instrument.currency, client.portfolios[0].currency, p.amount, rates)
      }
    })
    return total
  }

  const total = () => {
    let total = 0
    portfolio.positions.map(p => {
      total += exchangeRate(p.instrument.currency, client.portfolios[0].currency, p.amount, rates)
    })
    console.log(total, 'COUNTED TOTAL')
  }

  return (
    <Paper className={classes.totalsContainer}>
      <span className={`${classes.PortTotal} ${classes.boldText} ${classes.rowDisplay} ${classes.marginRight}`}>
        <span className={`${classes.textColor}  ${classes.marginLeft}`}>Celkový objem:</span>
        <span className={`${classes.separator}`} />
        <NumberFormat
          value={Math.round(((client.portfolios[0].amount || 0) + Number.EPSILON) * 100) / 100}
          displayType="text"
          thousandSeparator=" "
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
        />
        <span className={`${classes.marginLeftSmall} ${classes.marginRight}`}>CZK</span>
      </span>

      <span className={`${classes.fontSizeTotal} ${classes.rowDisplay} ${classes.marginRight}`}>
        <span className={`${classes.fontSizeTotal} ${classes.textColor}  ${classes.marginLeft}`}>Cenné papíry: </span>
        <span className={`${classes.separator}`} />
        <NumberFormat
          value={Math.round((assetTotal() + Number.EPSILON) * 100) / 100}
          displayType="text"
          thousandSeparator=" "
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
        />
        <span className={`${classes.marginLeftSmall} ${classes.marginRight}`}>CZK</span>
      </span>
      <span className={`${classes.fontSizeTotal} ${classes.rowDisplay} ${classes.marginRight}`}>
        <span className={`${classes.fontSizeTotal} ${classes.textColor}  ${classes.marginLeft}`}>
          Zůstatky na účtech:{' '}
        </span>
        <span className={`${classes.separator}`} />
        <NumberFormat
          value={Math.round((accountTotal() + Number.EPSILON) * 100) / 100}
          displayType="text"
          thousandSeparator=" "
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
        />
        <span className={`${classes.marginLeftSmall} ${classes.marginRight}`}>CZK</span>
      </span>
    </Paper>
  )
}
