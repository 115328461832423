import { makeStyles } from '@material-ui/core'
import React from 'react'
import { ColoredIconButton } from './ColoredIconButton'
import HomeIcon from '@material-ui/icons/Home'
import SettingsIcon from '@material-ui/icons/Settings'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Pages } from '../../router/router'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonMargin: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

export const LeftNavBar = () => {
  const classes = useStyles()
  const history = useHistory()

  const onNavButtonClick = (path: string) => () => {
    history.push(path)
  }

  return (
    <div className={classes.root}>
      <ColoredIconButton className={classes.buttonMargin} icon={<HomeIcon />} onClick={onNavButtonClick(Pages.Home)} />
      <ColoredIconButton className={classes.buttonMargin} icon={<SettingsIcon />} />
      <ColoredIconButton className={classes.buttonMargin} icon={<PhoneAndroidIcon />} />
      <ColoredIconButton className={classes.buttonMargin} icon={<SaveAltIcon />} />
      <ColoredIconButton className={classes.buttonMargin} icon={<MoreVertIcon />} />
    </div>
  )
}
