export const SET_USER_INFO = 'SET_USER_INFO'
export const LOG_OUT = 'LOG_OUT'

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENT = 'GET_CLIENT'
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const DEACTIVATE_CLIENT = 'DEACTIVATE_CLIENT'
export const GET_CLIENT_ROLES = 'GET_CLIENT_ROLES'
export const GET_CLIENT_ACCOUNT_TOTAL = 'GET_CLIENT_ACCOUNT_TOTAL'

export const FILTER_CLIENT_NAME = 'FILTER_CLIENT_NAME'
export const ORDER_CLIENTS = 'ORDER_CLIENTS'
export const FILTER_PORTFOLIO_DETAIL = 'FILTER_PORTFOLIO_DETAIL'
export const FILTER_INSTRUMENT_NAME = 'FILTER_INSTRUMENT_NAME'
export const FILTER_MARKET_NAME = 'FILTER_MARKET_NAME'
export const FILTER_AMOUNT_UP = 'FILTER_AMOUNT_UP'
export const FILTER_AMOUNT_DOWN = 'FILTER_AMOUNT_DOWN'
export const SORT_ORDERS = 'SORT_ORDERS'
export const FILTER_CLIENT_BY_INSTRUMENT_NAME = 'FILTER_CLIENT_BY_INSTRUMENT_NAME'
export const FILTER_CURRENCY_NAME = 'FILTER_CURRENCY_NAME'

export const GET_SECURITIES = 'GET_SECURITIES'
export const GET_SECURITY = 'GET_SECURITY'
export const GET_FILTERED_SECURITIES = 'GET_FILTERED_SECURITIES'

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDERS = 'GET_ORDERS'
export const ADD_ORDER = 'ADD_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const GET_ORDERS_BY_STATE_COUNT = 'GET_ORDERS_BY_STATE_COUNT'
export const GET_ORDER_TABLE_ROWS = 'GET_ORDER_TABLE_ROWS'
export const EXPORT_ORDERS = 'EXPORT_ORDERS'

export const GET_PORTFOLIO = 'GET_PORTFOLIO'

export const SHOW_PORTFOLIO_DETAIL = 'SHOW_PORTFOLIO_DETAIL'
export const IS_FORM_VALID = 'IS_FORM_VALID'
export const SET_ERRORS = 'SET_ERRORS'

export const GET_RATES = 'GET_RATES'

export const START_LOADING = 'START_LOADING'
export const FINISH_LOADING = 'FINISH_LOADING'
