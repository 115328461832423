import { InputAdornment, makeStyles, TextField } from '@material-ui/core'
import React from 'react'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { TransparentIconButton } from '../common/TransparentIconButton'
import { useTranslation } from 'react-i18next'
import { StringDecoder } from 'node:string_decoder'

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main,
  },
}))

interface OptionItem {
  label: string
  value: string | number
}

interface Props {
  value: any
  onChange: (value: string | number) => void
  options: OptionItem[]
  label: string
  disabled?: boolean
}

export const ArrowSelect: React.FC<Props> = ({ onChange, value, options, label, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onChangeHandler = (direction: -1 | 1) => () => {
    const currentIndex = options.map(o => t(o.value as string)).indexOf(value)
    console.log(options, value, currentIndex, 'ARROW SELECT')
    let nextIndex = currentIndex + direction
    if (nextIndex < 0) {
      nextIndex = options.length - 1
      console.log(options.length, 'OPTIONS LENGTH')
    }
    if (nextIndex > options.length - 1) {
      nextIndex = 0
    }
    onChange(options[nextIndex].value)
  }

  return (
    <TextField
      disabled={disabled}
      label={label}
      fullWidth={true}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TransparentIconButton
              className={classes.color}
              size="small"
              onClick={onChangeHandler(-1)}
              icon={<ArrowLeftIcon />}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <TransparentIconButton
              className={classes.color}
              size="small"
              onClick={onChangeHandler(1)}
              icon={<ArrowRightIcon />}
            />
          </InputAdornment>
        ),
        readOnly: false,
      }}
    ></TextField>
  )
}
