import { emptySecurity, spoofSecurity } from '../../constants/initialStates'
import { ReduxAction } from '../../types/common'
import { GET_FILTERED_SECURITIES, GET_SECURITIES, GET_SECURITY } from '../actionTypes'

export interface Security {
  assetClass: string
  currency: string
  isin: string
  symbol: string
  ticker: string
  name: string
  securityId: number
  market: string
  lastQuotePrice: number
  lastPriceIndication: number
  lastQuoteDate: string
  instrumentType: string
}

interface SecuritiesState {
  securities: Security[]
  security: Security | undefined
  filteredSecurities: Security[]
}

const INITIAL_STATE = {
  securities: [],
  security: undefined,
  filteredSecurities: [],
}

export const securities = (state: SecuritiesState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_SECURITIES:
      return {
        ...state,
        securities: action.payload as Security[],
      }
    case GET_SECURITY:
      return {
        ...state,
        security: action.payload as Security,
      }
    case GET_FILTERED_SECURITIES:
      return {
        ...state,
        filteredSecurities: action.payload as Security[],
      }
    default:
      return state
  }
}
