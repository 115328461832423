import { useSelector } from 'react-redux'
import { getClientsByParamRequest } from '../api/clientAPI'
import { Client } from '../redux/reducers/clients'
import { ClientSortOptions } from '../redux/reducers/filters'
import { RootState } from '../redux/reducers/root'
import { useClientApi } from './useClientApi'

export const useClientFilters = () => {
  const clients: Client[] = useSelector((state: RootState) => state.clients.clients)
  const filters = useSelector((state: RootState) => state.filters)

  const filteredClients = clients.filter(
    (client: Client) =>
      `${client.firstName}${client.surname}`.toLowerCase().includes(filters.searchedClientName.toLowerCase()) &&
      (client.portfolios[0].amount || 0) >= (filters.searchedAmountUp === '' ? -Infinity : filters.searchedAmountUp) &&
      (client.portfolios[0].amount || 0) <= (filters.searchedAmountDown === '' ? Infinity : filters.searchedAmountDown)
  )
  switch (filters.sortBy) {
    case ClientSortOptions.Az:
      filteredClients.sort((a, b) => (a.surname || '').localeCompare(b.surname || ''))
      break
    case ClientSortOptions.Za:
      filteredClients.sort((a, b) => (b.surname || '').localeCompare(a.surname || ''))
      break
    case ClientSortOptions.AmountUp:
      filteredClients.sort((a, b) => (a.portfolios[0].amount || 0) - (b.portfolios[0].amount || 1))
      break
    case ClientSortOptions.AmountDown:
      filteredClients.sort((a, b) => (b.portfolios[0].amount || 1) - (a.portfolios[0].amount || 0))
  }

  return filteredClients
}
