import { Grid, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import manS from '../../assets/manS.jpg'
import React, { useEffect, useState } from 'react'
import { Client } from '../../redux/reducers/clients'
import { useSelector } from '../../redux/hooks/useSelector'

const useStyles = makeStyles(theme => ({
  manIcon: {
    width: '100%',
  },
}))

interface Props {
  client: Client
  onClientAttributeChange: (name: string) => (value: any) => void
}

export const ClientNameContainer: React.FC<Props> = ({ onClientAttributeChange, client }) => {
  const classes = useStyles()
  const errors = useSelector(state => state.useractions.errors)

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  return (
    <>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img alt="" className={classes.manIcon} src={manS} />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth id="standard-basic" label="UID" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Jméno"
                  onChange={onClientInputChangeCreator('firstName')}
                  value={client.firstName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Titul před jménem"
              onChange={onClientInputChangeCreator('titlePre')}
              value={client.titlePre}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Titul za jménem"
              onChange={onClientInputChangeCreator('titleAfter')}
              value={client.titleAfter}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              helperText={errors['surname'] ? 'Toto pole je povinné' : ''}
              error={errors['surname']}
              id="standard-basic"
              label="Příjmení"
              onChange={onClientInputChangeCreator('surname')}
              value={client.surname}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
