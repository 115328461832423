import {
  FILTER_AMOUNT_DOWN,
  FILTER_AMOUNT_UP,
  FILTER_CLIENT_BY_INSTRUMENT_NAME,
  FILTER_CLIENT_NAME,
  FILTER_INSTRUMENT_NAME,
  FILTER_MARKET_NAME,
  FILTER_PORTFOLIO_DETAIL,
  ORDER_CLIENTS,
  SORT_ORDERS,
} from '../actionTypes'
import { Client } from '../reducers/clients'
import { ClientSortOptions, OrdersByStateOptions } from '../reducers/filters'
import { FiltersState } from '../reducers/filters'

export const fliterClientName = (searchedClientName: string) => ({
  type: FILTER_CLIENT_NAME,
  payload: searchedClientName,
})

export const sortClients = (value: ClientSortOptions) => ({
  type: ORDER_CLIENTS,
  payload: value,
})

export const filterPortfolioDetail = (name: string, value: boolean) => ({
  type: FILTER_PORTFOLIO_DETAIL,
  payload: { name, value },
})

export const filterInstrumentName = (searchedInstrumentName: string) => ({
  type: FILTER_INSTRUMENT_NAME,
  payload: searchedInstrumentName,
})

export const filterMarketName = (searchedMarketName: string) => ({
  type: FILTER_MARKET_NAME,
  payload: searchedMarketName,
})

export const filterClientAmountUp = (searchedAmountUp: string) => ({
  type: FILTER_AMOUNT_UP,
  payload: searchedAmountUp,
})

export const filterClientAmountDown = (searchedAmountDown: string) => ({
  type: FILTER_AMOUNT_DOWN,
  payload: searchedAmountDown,
})

export const filterClientByInstrumentName = (searchedClientByInstrumentName: string) => ({
  type: FILTER_CLIENT_BY_INSTRUMENT_NAME,
  payload: searchedClientByInstrumentName,
})

export const sortOrderTable = (value: OrdersByStateOptions) => ({ type: SORT_ORDERS, payload: value })

export const filterCurrencyName = (searchedCurrencyName: string) => ({
  type: FILTER_CLIENT_NAME,
  payload: searchedCurrencyName,
})
