import React from 'react'
import { ReduxAction } from '../../types/common'
import { FINISH_LOADING, START_LOADING } from '../actionTypes'

interface LoadingState {
  isLoading: string[]
}

const INITIAL_STATE = {
  isLoading: [],
}

export const loading = (state: LoadingState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      }
    case FINISH_LOADING:
      return {
        ...state,
        isLoading: state.isLoading.filter(e => e !== action.payload),
      }
    default:
      return state
  }
}
