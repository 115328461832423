export const ORDER_DIRECTION = 'ORDER_DIRECTION'
export const ORDER_TYPE = 'ORDER_TYPE'
export const ORDER_VALIDITY = 'ORDER_VALIDITY'
export const ORDER_MARKET_TYPE = 'ORDER_MARKET_TYPE'

export const ORDER_TYPE_QUANTITY = 'OT_QUANTITY'
export const ORDER_TYPE_AMOUNT = 'OT_AMOUNT'
export const ORDER_MARKET_TYPE_LIMIT = 'OMT_LIMIT'
export const ORDER_MARKET_TYPE_MARKET = 'OMT_MARKET'
export const ORDER_VALIDITY_DATE = 'OV_GTD'

export const ORDER_STATE_NEW = "OS_NEW";
export const ORDER_STATE_CONFIRMED = "OS_CONFIRMED";
export const ORDER_STATE_CANCELED = "OS_CANCELED";
export const ORDER_STATE_EXPIRED = "OS_EXPIRED";
export const ORDER_STATE_REJECTED = "OS_REJECTED";
export const ORDER_STATE_FILLED = "OS_FILLED";
export const ORDER_STATE_PARTIAL_FILLED = "OS_PARTIAL_FILLED";
export const ORDER_STATE_PARTIAL_EXPIRED = "OS_PARTIAL_EXPIRED";
export const ORDER_STATE_PARTIAL_CANCELED = "OS_PARTIAL_CANCELED";

export const ACTION_USER_SEND_TO_CANCEL = "AU_ST_CANCEL";
export const ACTION_USER_SEND_TO_CONFIRM = "AU_ST_CONFIRM";
export const ACTION_ITRADER_SEND_TO_REJECT = "AI_ST_REJECT";
export const ACTION_BROKER_SEND_TO_CANCEL = "AB_ST_CANCEL";
export const ACTION_BROKER_SEND_TO_REJECT = "AB_ST_REJECT";
export const ACTION_USER_REQUEST_TO_CANCEL = "AU_RT_CANCEL";
export const ACTION_BROKER_SEND_TO_EXPIRE = "AB_ST_EXPIRE";
export const ACTION_BROKER_SEND_TO_PARTIAL_FILL = "AB_ST_PARTIAL_FILL";
export const ACTION_BROKER_SEND_TO_FILL = "AB_ST_FILL";
export const ACTION_USER_SEND_TO_PARTIAL_FILL_CANCEL = "AU_ST_PARTIAL_FILL_CANCEL";
export const ACTION_BROKER_SEND_TO_PARTIAL_FILL_EXPIRED = "AB_ST_PARTIAL_FILL_EXPIRED";
