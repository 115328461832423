import { createMuiTheme } from '@material-ui/core'

export const customThemeObject = {
  palette: {
    buy: {
      main: '#fff',
    },
    sell: {
      main: '#f9f3e5',
    },
  },
  typography: {
    fontSize: 12,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    fontWeightLight: 200,
    fontFamily:
      'Roboto,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  overrides: {
    apexcharts: {
      datalabels: {
        group: {
          fontSize: 30,
        },
      },
    },
  },
}
