import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@material-ui/icons/Add'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { Security } from '../../redux/reducers/securities'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { useTranslation } from 'react-i18next'
import { CURRENCY, DATA_FROM, MARKET, MOVEMENT, NAME, PRICE } from '../../constants/textConstants'

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  boldText: {
    fontWeight: 'bold',
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(0.4),
  },
  separator: {
    flexGrow: 1,
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
  separatorLeftHuge: {
    marginLeft: theme.spacing(10),
  },
  separatorLeftLarge: {
    marginLeft: theme.spacing(5),
  },
  borderColorShare: {
    borderColor: theme.palette.error.main,
  },
  borderColorFcMarket: {
    borderColor: theme.palette.success.main,
  },
  borderColorBond: {
    borderColor: theme.palette.primary.main,
  },
  borderColorOther: {
    borderColor: theme.palette.grey[600],
  },
  textColorGreen: {
    color: theme.palette.success.main,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '320px',
    minWidth: '200px',
  },
  instrumentTableButton: {
    height: '15px',
    width: '40px',
    marginRight: theme.spacing(2),
  },
  buttonIcon: {
    height: '12px',
    width: '12px',
  },
  iconSize: {
    height: '15px',
    width: '23px',
    lineHeight: '15px',
    fontSize: '8px',
  },
  market: {
    width: '100px',
  },
}))

export const InstrumentTableHeader = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.tableRow}>
      <div className={`${classes.iconSize}`} />
      <Typography className={`${classes.textColor}  ${classes.separatorLeft} ${classes.name}`}>{t(NAME)}</Typography>
      <Typography className={`${classes.textColor}  ${classes.separatorLeft} ${classes.market}`}>
        {t(MARKET)}
      </Typography>
      <Typography className={`${classes.textColor}  ${classes.separatorLeft}`}>{t(CURRENCY)}</Typography>
      <Typography className={`${classes.textColor} ${classes.separatorLeftLarge}`}>{t(PRICE)}</Typography>
      <div className={classes.separator}></div>
      <Typography className={`${classes.textColor} ${classes.separatorLeftSmall}`}>{t(MOVEMENT)}</Typography>
      <Typography className={`${classes.textColor} ${classes.separatorLeft}`}>{t(DATA_FROM)}</Typography>
      <div className={`${classes.instrumentTableButton} ${classes.separatorLeft}`} />
    </div>
  )
}
