import { Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { useClientApi } from '../../hooks/useClientApi'
import { useRiskProfileColor } from '../../hooks/useRiskProfileColor'
import { Client } from '../../redux/reducers/clients'
import { Pages } from '../../router/router'

// TODO
const useStyles = makeStyles(theme => ({
  clientTileContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '2px',
    height: '125px',
    maxWidth: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  clientInfo: {
    flexGrow: 1,
    textAlign: 'left',
    color: theme.palette.primary.main,
    maxWidth: 'inherit',
    boxSizing: 'border-box',
    marginLeft: theme.spacing(1.25),
  },
  balanceContainer: {
    textAlign: 'right',
  },
  clientDetail: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  separator: {
    height: '3px',
    backgroundColor: theme.palette.primary.main,
  },
  name: {},
  phone: {},
  balance: {
    color: theme.palette.primary.main,
  },
  detailButton: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  riskProfileBasic: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    minWidth: '45px',
    borderRadius: '50%',
  },
  topRowContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'inherit',
  },
  positionAid: {
    minWidth: '25px',
  },
}))

interface Props {
  client: Client
}

export const ClientTile: React.FC<Props> = ({ client }) => {
  const classes = useStyles()
  const history = useHistory()
  const riskProfileColor = useRiskProfileColor(client)

  const onDetailButtonClick = () => {
    history.push(Pages.ClientDetail + `/${client.clientId}`)
  }

  return (
    <div className={classes.clientTileContainer}>
      <div className={classes.topRowContainer}>
        {/* <div className={classes.riskProfileBasic + ' ' + riskProfileColor}>
          <Typography variant="h4" color="secondary">
            {client.riskProfile}
          </Typography>
          </div> */}
        <div className={classes.clientInfo}>
          <Typography variant="h5" className={classes.name}>
            {client.surname}
          </Typography>
          <Typography>{client.firstName}</Typography>
          <Typography className={classes.phone}>
            <NumberFormat value={client.telephoneNumber} displayType={'text'} format="### ### ### ###" />
          </Typography>
        </div>
        <div className={classes.positionAid}></div>
      </div>
      <div className={classes.clientDetail}>
        <div className={classes.balanceContainer}>
          <Typography className={classes.balance}>
            <NumberFormat
              value={Math.round(((client.portfolios[0].amount || 0) + Number.EPSILON) * 100) / 100}
              displayType="text"
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator=" "
            />{' '}
            {client.portfolios[0].currency}
          </Typography>
        </div>
        <div className={classes.separator}></div>
        <Button className={classes.detailButton} onClick={onDetailButtonClick}>
          Detail
        </Button>
      </div>
    </div>
  )
}
