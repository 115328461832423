import { Button, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { DropZone } from '../common/DropZone'
import { Client } from '../../redux/reducers/clients'

const useStyles = makeStyles(theme => ({
  actionButtons: {
    width: '100%',
  },
  border: {
    border: `2px dashed ${theme.palette.grey[500]}`,
  },
}))

interface Props {
  client: Client
  handleClose: () => void
  handleSave: () => void
}

export const ClientInformationDialogFooter: React.FC<Props> = ({ client, handleClose, handleSave }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DropZone defaultText="Vložte osobní doklad" className={classes.border} />
      </Grid>
      <Grid item xs={6}>
        <DropZone defaultText="Vložte podepsaný investiční dotazník" className={classes.border} />
      </Grid>
      <Grid item xs={6}>
        <Button
          className={classes.actionButtons}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          Storno
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button className={classes.actionButtons} size="large" variant="contained" color="primary" onClick={handleSave}>
          Hotovo
        </Button>
      </Grid>
    </Grid>
  )
}
