import { FormControlLabel, makeStyles, Paper, Switch, Typography } from '@material-ui/core'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { usePortfolioTotals } from '../../hooks/usePortfolioTotals'
import { filterPortfolioDetail } from '../../redux/actions/filterActions'
import { useSelector } from '../../redux/hooks/useSelector'
import { OrderInputDialog } from '../dialogs/OrderInputDialog'
import { PortfolioDonutChart } from './PortfolioDonutChart'
import { PortfolioEnhancedDetailTable } from './PortfolioEnhancedDetailTable'
import { PortfolioEnhancedDetailTopBar } from './PortfolioEnhancedDetailTopBar'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    maxHeight: 'calc(100vh - 280px)',
    minHeight: 'calc(100vh - 280px)',
  },
  infoWindowActions: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    height: '20px',
  },
  footer: {
    height: '35%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '15%',
  },
  tableFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  name: {
    display: 'inline-block',
  },
  fontSizeLegend: {
    fontSize: '12px',
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(0.4),
  },
  separator: {
    flexGrow: 1,
  },
  portfolioCurrencyTotal: {
    textAlign: 'end',
  },
  fontSize: {
    fontSize: '14px',
  },
  iconMock: {
    width: '25px',
  },
  textEnd: {
    textAlign: 'end',
  },
  instrumentTableButton: {
    width: '45px',
    marginRight: theme.spacing(1),
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
}))

export const PortfolioEnhancedDetail = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const client = useSelector(state => state.clients.client)

  return (
    <>
      <Paper className={classes.root}>
        <PortfolioEnhancedDetailTopBar />
        <PortfolioEnhancedDetailTable />
      </Paper>
    </>
  )
}
