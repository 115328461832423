import React, { useLayoutEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from '../../redux/hooks/useSelector'
import { DropZone } from '../common/DropZone'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { FILE_IMPORT_DIALOG_ID } from '../../constants/dialogConstants'
import { Button, CircularProgress, DialogContent, makeStyles } from '@material-ui/core'
import { fileToBase64 } from '../../utils/fileToBase64'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TransparentButton } from '../common/TransparentButton'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    height: '200px',
    width: '400px',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  border: {
    border: `2px dashed ${theme.palette.grey[500]}`,
  },
  dropContainer: {
    height: '50%',
    width: '100%',
  },
  topHalfContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    height: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
    height: '40px',
    width: '100px',
  },
}))

export const FileImportDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const foundDialog = dialogs.find(dialog => dialog.id === 'FILE_IMPORT_DIALOG_ID')
  const open = !!foundDialog
  const [fileToSend, setFileToSend] = useState<null | string>(null)
  const { importFile } = useInstrumentApi()
  const loaders = useSelector(state => state.loading.isLoading)
  const isImportLoading = loaders.find(e => e === 'importFile')

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(FILE_IMPORT_DIALOG_ID))
    }
  }, [])

  useEffect(() => {}, [loaders])

  const handleClose = () => {
    dispatch(closeDialog(FILE_IMPORT_DIALOG_ID))
  }

  const onFileDrop = (acceptedFiles: File[]) => {
    fileToBase64(acceptedFiles[0]).then(result => setFileToSend(result))
  }

  const onImportClick = () => {
    importFile({
      content: fileToSend,
    })
    setFileToSend(null)
  }

  return (
    <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent className={classes.dialogContainer}>
        <div className={classes.dropContainer}>
          {isImportLoading ? (
            <div className={classes.topHalfContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.topHalfContainer}>
              <DropZone
                onFileDrop={onFileDrop}
                defaultText="Klikněte nebo přetáhněte soubor"
                className={classes.border}
              />
            </div>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClose}
            disabled={isImportLoading}
          >
            Storno
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onImportClick}
            disabled={isImportLoading}
          >
            Potvrdit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
