import DateFnsUtils from '@date-io/date-fns'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { useSelector } from '../../redux/hooks/useSelector'
import { Client } from '../../redux/reducers/clients'
import { DateInput } from '../common/DateInput'
import { PhoneNumberInput } from '../common/PhoneNumberInput'

const useStyles = makeStyles(theme => ({
  marginTopOverride: {
    marginTop: '0px',
  },
}))

interface Props {
  client: Client
  onClientAttributeChange: (name: string) => (value: any) => void
  onClientPermanentAddressChange: (name: string) => (value: any) => void
  onClientDateChange: (name: string) => (value: any) => void
}

export const ClientPersonalDataContainer: React.FC<Props> = ({
  client,
  onClientAttributeChange,
  onClientPermanentAddressChange,
  onClientDateChange,
}) => {
  const classes = useStyles()
  const errors = useSelector(state => state.useractions.errors)

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  const onClientAddressInputChangeCreator = (name: string) => (event: any) => {
    onClientPermanentAddressChange(name)(event.target.value)
  }

  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Národnost"
          onChange={onClientInputChangeCreator('nationality')}
          value={client?.nationality}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Rodné číslo"
          helperText={errors['personalNumber'] ? 'Toto pole je povinné' : ''}
          error={errors['personalNumber']}
          onChange={onClientInputChangeCreator('personalNumber')}
          value={client?.personalNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Emailová adresa"
          onChange={onClientInputChangeCreator('email')}
          value={client?.email}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneNumberInput
          fullWidth
          id="standard-basic"
          label="Telefonní číslo"
          onChange={onClientInputChangeCreator('telephoneNumber')}
          value={client?.telephoneNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Číslo osobního dokladu"
          helperText={errors['identityCardNumber'] ? 'Toto pole je povinné' : ''}
          error={errors['identityCardNumber']}
          onChange={onClientInputChangeCreator('identityCardNumber')}
          value={client?.identityCardNumber}
        />
      </Grid>
      <Grid item xs={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.marginTopOverride}
            helperText={false}
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Platnost OD"
            value={client?.identityCardValidity}
            onChange={onClientDateChange('identityCardValidity')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="standard-basic"
          label="OD vydán v"
          onChange={onClientInputChangeCreator('identityCardIssuer')}
          value={client?.identityCardIssuer}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Ulice č.p."
          helperText={errors['street'] ? 'Toto pole je povinné' : ''}
          error={errors['street']}
          onChange={onClientAddressInputChangeCreator('street')}
          value={client.permanentAddress?.street}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Město"
          helperText={errors['town'] ? 'Toto pole je povinné' : ''}
          error={errors['town']}
          onChange={onClientAddressInputChangeCreator('town')}
          value={client.permanentAddress?.town}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="PSČ"
          helperText={errors['zipCode'] ? 'Toto pole je povinné' : ''}
          error={errors['zipCode']}
          onChange={onClientAddressInputChangeCreator('zipCode')}
          value={client.permanentAddress?.zipCode}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Stát"
          helperText={errors['country'] ? 'Toto pole je povinné' : ''}
          error={errors['country']}
          onChange={onClientAddressInputChangeCreator('country')}
          value={client.permanentAddress?.country}
        />
      </Grid>
    </>
  )
}
