import React from 'react'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import { ClientList } from '../containers/ClientList'
import { ClientDetail } from '../containers/ClientDetail'
import { OrderList } from '../containers/OrderList'
import { useKeycloak } from '@react-keycloak/web'
import { PrivateRoute } from './routes/PrivateRoute'
import LoginPage from '../containers/LoginPage'

export enum Pages {
  Home = '/',
  Login = '/login',
  ClientDetail = '/client-detail',
  OrderList = '/order-list',
}

export const AppRouter = () => {
  const { initialized } = useKeycloak()
  const { pathname } = useLocation()

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <Switch>
      <PrivateRoute path={Pages.ClientDetail + '/:clientId'} component={ClientDetail} />
      <PrivateRoute path={Pages.OrderList} component={OrderList} />
      <PrivateRoute path={Pages.Home} component={ClientList} />
      <Route path="/login" component={LoginPage} />
    </Switch>
  )
}
