import { ReduxAction } from '../../types/common'
import { GET_RATES } from '../actionTypes'

export interface Rate {
  date: string
  currencyFrom: string
  currencyTo: string
  rate: number
  value: number
}

interface CurrencyState {
  rates: Rate[]
}

const INITIAL_STATE = {
  rates: [],
}

export const currency = (state: CurrencyState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_RATES:
      return {
        ...state,
        rates: action.payload as Rate[],
      }
    default:
      return state
  }
}
