import React, { ComponentType } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { Pages } from '../router'
import { useKeycloak } from '@react-keycloak/web'

interface PrivateRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteParams) {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
      render={props =>
        keycloak?.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Pages.Login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
