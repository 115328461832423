import { useSelector } from 'react-redux'
import { InstrumentIcon } from '../components/common/InstrumentIcon'
import { RootState } from '../redux/reducers/root'
import { Security } from '../redux/reducers/securities'

export const useInstrumentFilters = () => {
  const instruments: Security[] = useSelector((state: RootState) => state.securities.securities)
  const filters = useSelector((state: RootState) => state.filters)

  const filteredInstruments: any = instruments.filter((instrument: Security) =>
    instrument.market.toLowerCase().includes(filters.searchedMarketName.toLowerCase())
  )
  return filteredInstruments
}
