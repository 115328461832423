import { makeStyles } from '@material-ui/core'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useCreateDonutChartSeries } from '../../hooks/useCreateDonutChartSeries'
import { usePortfolioTotals } from '../../hooks/usePortfolioTotals'
import { themeObject } from '../../muiTheme'

const useStyles = makeStyles(theme => ({
  chartContainer: {
    height: '100%',
    width: '100%',
  },
}))

export const PortfolioDonutChart = () => {
  const { donutDataSeries, donutDataLabels, colors } = usePortfolioTotals()
  const { donutSeriesValues, donutSeriesLabels, donutSeriesColors } = useCreateDonutChartSeries()
  const classes = useStyles()

  const total = donutDataSeries.reduce((a: any, b: any) => {
    return a + b
  }, 0)

  const formattedTotal = () => {
    if ((total > 0 ? total : -total) >= 1000000) {
      return (Math.round((total / 1000000 + Number.EPSILON) * 100) / 100).toLocaleString()
    } else {
      return (Math.round((total / 1000 + Number.EPSILON) * 100) / 100).toLocaleString()
    }
  }

  const state = {
    options: {
      stroke: {
        width: 0,
      },
      tooltip: {
        y: {
          formatter: (w: any) => Number(w)?.toLocaleString(),
        },
      },
      plotOptions: {
        stroke: {
          show: false,
        },
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                show: true,
                color: undefined,
                offsetY: 10,
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '35px',
                fontWeight: themeObject.typography.fontWeightBold,
                color: themeObject.palette.primary.dark,
                offsetY: 30,
                label: formattedTotal() as string,
                formatter: function (w: any) {
                  if ((total > 0 ? total : -total) >= 1000000) {
                    return 'mio CZK'
                  } else {
                    return 'tis CZK'
                  }
                },
              },
              value: {
                show: true,
                fontSize: '15px',
                fontWeight: themeObject.typography.fontWeightLight,
                color: themeObject.palette.primary.main,
                offsetY: 15,
                formatter: function (w: any) {
                  return
                },
              },
            },
          },
        },
      },
      colors: donutSeriesColors,
      fill: {
        pattern: {
          strokeWidth: 6,
        },
      },

      series: donutSeriesValues,
      labels: donutSeriesLabels,
      chart: {
        type: 'donut',
        total: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
        formatter: function (w: any) {
          return 'hello'
        },
      },
    },
  }

  return (
    <div id="chart" className={classes.chartContainer}>
      <ReactApexChart width="100%" height="100%" options={state.options} series={state.options.series} type="donut" />
    </div>
  )
}
