import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  infoWindowTopBar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  separator: {
    flexGrow: 1,
  },
  instrumentsTitle: {
    marginLeft: theme.spacing(1),
  },
  portfolioTableContainer: {
    height: '100%',
  },
  infoWindowContainer: {
    display: 'flex',
    height: 'calc(100% - 40px)',
    flexGrow: 1,
    flexDirection: 'column',
  },
  infoWindowActions: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    height: '10%',
  },
  infoWindowData: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '90%',
  },
  chartContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '30%',
  },
  portfolioDetailContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  portfolioDetailTitle: {
    width: '100%',
    height: '10%',
  },

  donutChart: {
    height: '100%',
    width: '80%',
    display: 'flex',
    alignItems: 'center',
  },
  tableListScroll: {
    maxHeight: '300px',
  },
  detailButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))
