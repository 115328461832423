import { useSelector } from '../redux/hooks/useSelector'
import { useSortPortfolios } from './useSortPortfolios'

export const useGetActivePortfolioAssets = () => {
  const { portfolioAssetsByClass, portfolioAssetsByInstrumentType, portfolioAssetsByCurrency } = useSortPortfolios()
  const portfolioDetailFilter = useSelector(state => state.filters.portfolioDetail)

  if (portfolioDetailFilter.class) {
    return portfolioAssetsByClass
  } else if (portfolioDetailFilter.currency) {
    return portfolioAssetsByCurrency
  } else if (portfolioDetailFilter.instrumentType) {
    return portfolioAssetsByInstrumentType
  }

  return []
}
