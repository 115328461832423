import Keycloak from 'keycloak-js'
import i18next from 'i18next'
import { fetchRequest } from './http.service'
import { SET_USER_INFO } from '../redux/actionTypes'

const keycloakUrl = process.env.REACT_APP_KEYCLOAK
const keycloakRN = process.env.REACT_APP_KEYCLOAK_RN

export const keycloak = new Keycloak({
  clientId: 'itrader-front',
  realm: keycloakRN,
  bearerOnly: true,
  url: keycloakUrl,
  'auth-server-url': keycloakUrl,
  'ssl-required': 'none',
  resource: 'itrader-front',
  'public-client': false,

  'enable-cors': true,
  'cors-allowed-methods': 'GET, PUT, POST, HEAD, OPTIONS',
  'cors-allowed-headers': 'Origin, Accept, X-Requested-With, Content-Type, Last-Event-ID',
})

export const checkKeycloakAuthentication = () => {
  if (!keycloak.isTokenExpired(30)) {
    return
  }

  keycloak
    .updateToken(30)
    .then(refreshed => {
      if (refreshed) {
        console.log('Token was successfully refreshed')
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export const logout = store => {
  keycloak.logout()
}

export const loadAndStoreUser = (store, userInfo, userId) => {
  fetchRequest
    .get(`/users/${userId}`)
    .then(({ data }) => {
      store.dispatch({
        type: SET_USER_INFO,
        payload: {
          userId: userId,
          user: data,
        },
      })
    })
    .catch(err => {})

  /* const userLocale = userInfo.attributes.locale ? userInfo.attributes.locale[0] : 'cs'
  i18next.changeLanguage(userLocale).catch(() => {
    console.log('CANNOT CHANGE LANGUAGE')
  })
  console.log('USERLOCALE', userLocale) */
}

export const eventLogger = store => (event, error) => {
  console.log('onKeycloakEvent', event, error)
  if (event === 'onAuthSuccess') {
    keycloak
      .loadUserProfile()
      .success(userInfo => {
        loadAndStoreUser(store, userInfo, keycloak.subject)
      })
      .error(err => {
        console.log('loadUserProfile: ', err)
        logout(store)
      })
  } else if (error) {
    console.log('onKeycloakEvent', event, error)
  }
}
