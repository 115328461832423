import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useOrderFilters } from '../../hooks/useOrderFilters'
import { OrderTable } from './OrderTable'

const useStyles = makeStyles(theme => ({
  orderTable: {
    height: 'calc(100vh - 320px);',
    maxHeight: 'calc(100vh - 320px);',
    overflow: 'auto',
  },
}))

export const OrderDisplay = () => {
  const classes = useStyles()

  return (
    <div className={classes.orderTable}>
      <OrderTable />
    </div>
  )
}
