import { useTheme } from '@material-ui/core'
import { useSelector } from '../redux/hooks/useSelector'
import { Position } from '../redux/reducers/portfolio'
import { useExchangeRate } from './useExchangeRate'
import { useGetActivePortfolioAssets } from './useGetActivePortfolioAssets'

export const usePortfolioTotals = () => {
  const theme = useTheme()
  const rates = useSelector(state => state.currency.rates)
  const exchangeRate = useExchangeRate()
  const portfolio = useSelector(state => state.detailPortfolioState.portfolio)
  const portFolioAssets = useGetActivePortfolioAssets()
  const donutDataSeries = []
  const donutDataLabels = []
  const together = []
  const colors = [
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.primary.light,
    theme.palette.grey[600],
  ]

  //TODO better color assignment
  let colorIndex = 0
  for (const [name, portfolios] of Object.entries(portFolioAssets)) {
    let totalPortfolioCurrencyTotal = 0
    for (const asset of portfolios) {
      totalPortfolioCurrencyTotal += exchangeRate(asset.instrument.currency, portfolio.currency, asset.amount, rates)
    }
    donutDataSeries.push(totalPortfolioCurrencyTotal)
    donutDataLabels.push(name)
    together.push([totalPortfolioCurrencyTotal, name, colors[colorIndex]])
    console.log('COLOR INDEX', colorIndex)
    colorIndex++
  }

  let totalOfTotalPortfolioCurrencyTotal = 0
  for (const totalSeries of donutDataSeries) {
    totalOfTotalPortfolioCurrencyTotal += totalSeries
  }

  const percentageSeries = []
  for (const totalSeries of donutDataSeries) {
    percentageSeries.push(
      (Math.round((100 * totalSeries) / totalOfTotalPortfolioCurrencyTotal + Number.EPSILON) * 100) / 100
    )
  }

  const portFolioAssetsEnhanced: Record<
    string,
    { positions: Array<Position>; percentage: string; total: number; name: string; color: string }
  > = {}
  let index = 0
  for (const [name, portfolios] of Object.entries(portFolioAssets)) {
    portFolioAssetsEnhanced[name] = {
      positions: portfolios,
      percentage: `${percentageSeries[index]}`,
      total: donutDataSeries[index],
      name,
      color: colors[index],
    }
    index++
  }

  return {
    donutDataSeries,
    donutDataLabels,
    percentageSeries,
    portFolioAssetsEnhanced,
    colors,
    together,
  }
}
