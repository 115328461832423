import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../redux/hooks/useSelector'
import { ContactAdressInput } from '../ClientInformationDialog/ContactAdressInput'
import { ClientInformationDialogFooter } from '../ClientInformationDialog/ClientInformationDialogFooter'
import { closeDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import { ClientNameContainer } from '../ClientInformationDialog/ClientNameContainer'
import { ClientPersonalDataContainer } from '../ClientInformationDialog/ClientPersonalDataContainer'
import { Client, ClientRole, requiredClientAttributes } from '../../redux/reducers/clients'
import { emptyClient } from '../../constants/initialStates'
import { useClientApi } from '../../hooks/useClientApi'
import { DateInput } from '../common/DateInput'
import { useLocation } from 'react-router-dom'
import { changeFormValidity, setErrors } from '../../redux/actions/userActions'
import { ActionFailedAlert } from './ActionFailedAlert'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ActionAlert } from './ActionAlert'
import Input from '@material-ui/core/Input'
import Autocomplete from '@material-ui/lab/Autocomplete'
// TODO
const useStyles = makeStyles(theme => ({
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  separator: {
    flexGrow: 1,
  },
  clientInformationDialog: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  counterPartyIdContainer: {
    padding: theme.spacing(1),
  },
  counterpartyIdLabel: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  marginTopOverride: {
    marginTop: '0px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  chips: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}))

export const ClientInformationDialog = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const { postClient, updateClient, getClientRoles } = useClientApi()
  const foundDialog = dialogs.find(dialog => dialog.id === 'CLIENT_INFORMATION_DIALOG_ID')
  const open = !!foundDialog
  const [contactAdressCheckState, setContactAdressState] = React.useState(false)
  const [client, setClient] = useState<Client>(emptyClient)
  const clientRoles = useSelector(state => state.clients.clientRoles)
  const valid = useSelector(state => state.useractions.valid)
  const dispatch = useDispatch()
  const errors = useSelector(state => state.useractions.errors)
  const [clientSent, setClientSent] = useState(false)

  useEffect(() => {
    getClientRoles()
  }, [])

  useEffect(() => {
    if (foundDialog?.payload.client) setClient(foundDialog?.payload.client)
  }, [foundDialog])

  useEffect(() => {
    if (client.permanentAddress === null) {
      setClient({
        ...client,
        permanentAddress: {
          addressId: null,
          street: null,
          town: null,
          zipCode: null,
          country: null,
          addressType: 'PERMANENT',
        },
      })
    }
  })

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      setContactAdressState(false)
      setClient(emptyClient)
      dispatch(setErrors({}))
      dispatch(changeFormValidity(true))
      console.log('closeClientInformationOnLocationChangeFired')
    }
  }, [])

  const isValid = () => {
    let valid = true
    const errors: {
      [key: string]: boolean
    } = {}
    for (const [key, value] of Object.entries(client)) {
      if (!requiredClientAttributes.includes(key)) continue
      if (key === 'roles' && value.length === 0) {
        valid = false
        errors[key] = true
        continue
      }
      if (!value || (value && !String(value).trim())) {
        valid = false
        errors[key] = true
      }
    }

    for (const [key, value] of Object.entries(client.permanentAddress)) {
      console.log(client)
      if (!requiredClientAttributes.includes(key)) continue

      if (!value || (value && !String(value).trim())) {
        valid = false
        errors[key] = true
      }
    }

    dispatch(setErrors(errors))
    return valid
  }

  const contactAdressCheckStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactAdressState(event.target.checked)
    setClient({
      ...client,
      contactAddress: {
        addressId: null,
        street: null,
        town: null,
        zipCode: null,
        country: null,
        addressType: 'CONTACT',
      },
    })
  }

  const handleClose = () => {
    dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
    setContactAdressState(false)
    setClient(emptyClient)
    dispatch(setErrors({}))
    dispatch(changeFormValidity(true))
    console.log(valid, 'FORMVALID')
  }

  const onSubmitClick = () => {
    console.log(isValid(), 'isValid')
    if (isValid()) {
      console.log(client, 'SAVEDCLIENT')
      handleSave()
    }
  }

  const trimAllValues = (object: Record<string, any>) => {
    const objectCopy: Record<string, any> = { ...object }
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === 'string') {
        objectCopy[key] = value.trim()
      }
      if (typeof value === 'object' && value !== null) {
        objectCopy[key] = trimAllValues(value)
      }
    }
    return objectCopy
  }

  const handleSave = () => {
    if (clientSent) return
    setClientSent(true)
    const clientCopy = client //trimAllValues(client) as Client
    if (foundDialog?.payload.type === 'new') {
      postClient(clientCopy).finally(() => {
        setClientSent(false)
      })
    } else if (foundDialog?.payload.type === 'edit') {
      updateClient(clientCopy).finally(() => {
        setClientSent(false)
      })
    }
    console.log(clientCopy, 'SENTCLIENT')
  }

  const onClientAttributeChange = (name: string) => (value: any) => {
    setClient({ ...client, [name]: value })
    if (value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const onClientPermanentAddressChange = (name: string) => (value: any) => {
    setClient({ ...client, permanentAddress: { ...client.permanentAddress, [name]: value } })
    if (value.trim()) {
      dispatch(setErrors({ ...errors, [name]: undefined }))
    }
  }

  const onClientContactAddressChange = (name: string) => (value: any) => {
    setClient({ ...client, contactAddress: { ...client.contactAddress, [name]: value } })
  }

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  const handleDateChangeCreator = (name: string) => (date: Date | null) => {
    setClient({ ...client, [name]: date })
  }

  const onClientRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setClient({ ...client, roles: event.target.value as string[] })
    console.log(client.roles, 'ROLES')
  }

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
      <div className={classes.clientInformationDialogHeader}>
        <Typography variant="h5">{foundDialog?.payload.dialogTitle}</Typography>
      </div>
      <DialogContent className={classes.clientInformationDialog}>
        <Grid container spacing={1}>
          <ClientNameContainer client={client} onClientAttributeChange={onClientAttributeChange} />
          <Grid container spacing={2}>
            <ClientPersonalDataContainer
              client={client}
              onClientAttributeChange={onClientAttributeChange}
              onClientPermanentAddressChange={onClientPermanentAddressChange}
              onClientDateChange={handleDateChangeCreator}
            />
            {contactAdressCheckState && (
              <ContactAdressInput client={client} onClientContactAddressChange={onClientContactAddressChange} />
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contactAdressCheckState}
                    onChange={contactAdressCheckStateChange}
                    name="checked"
                    color="primary"
                  />
                }
                label="Kontaktní adresa se neshoduje"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                id="standard-basic"
                label="Poznámky"
                onChange={onClientInputChangeCreator('note')}
                value={client.note}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={`${classes.counterpartyIdLabel} ${errors['roles'] && classes.errorText}`}
              >
                Obsluha
              </Typography>
              <Paper
                variant="outlined"
                className={`${classes.counterPartyIdContainer} ${errors['roles'] && classes.errorBorder}`}
              >
                <FormControl fullWidth error={!valid}>
                  <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    filterSelectedOptions
                    options={clientRoles}
                    value={clientRoles.filter(r => client.roles.includes(r.name))}
                    getOptionLabel={(option: ClientRole) => option.name}
                    getOptionDisabled={option => !!client.roles.find(r => r === option.name)}
                    onChange={(event: any, value: ClientRole[]) => {
                      setClient({ ...client, roles: value.map(v => v.name) })
                    }}
                    renderTags={(value: ClientRole[], getTagProps) =>
                      value.map((option: ClientRole, index: number) => (
                        <Chip classes={{ root: classes.chips }} label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Role"
                        helperText={errors['roles'] ? 'Toto pole je povinné' : ''}
                        error={errors['roles']}
                      />
                    )}
                  />
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.counterpartyIdLabel}>
                ID protistran
              </Typography>
              <Paper variant="outlined" className={classes.counterPartyIdContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="J&T"
                      onChange={onClientInputChangeCreator('clientCounterpartyId')}
                      value={client.clientCounterpartyId}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.counterpartyIdLabel}>
                Investiční dotazník
              </Typography>
              <Paper variant="outlined" className={classes.counterPartyIdContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Investiční horizont"
                      onChange={onClientInputChangeCreator('investmentHorizon')}
                      value={client.investmentHorizon}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.marginTopOverride}
                        helperText={false}
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={client.investmentQuestionnaireValidTo}
                        onChange={handleDateChangeCreator('investmentQuestionnaireValidTo')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Rizikový profil"
                      onChange={onClientInputChangeCreator('riskProfile')}
                      value={client.riskProfile}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ClientInformationDialogFooter client={client} handleClose={handleClose} handleSave={onSubmitClick} />
      </DialogActions>
      <ActionFailedAlert />
      <ActionAlert />
    </Dialog>
  )
}
