import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { LeftNavBar } from '../common/LeftNavBar'
import { TopBar } from '../common/TopBar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  appBar: {
    width: '100%',
  },
  contentContainer: {
    width: 'calc(100% - 80px)',
    height: '100%',
    float: 'left',
  },
  leftNavbar: {
    width: '80px',
    height: '100%',
    float: 'left',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  appFooter: {
    width: '100%',
  },
  contentTitle: {
    marginLeft: theme.spacing(2),
    height: '5%',
  },
  centerContainer: {
    width: '100%',
    height: 'calc(100% - 100px)',
  },
}))

interface Props {
  appBar?: React.ReactNode
  leftNavigation?: React.ReactNode
  footer?: React.ReactNode
  contentTitle?: React.ReactNode
  className?: string
}

export const BasicLayout: React.FC<Props> = ({
  appBar = <TopBar />,
  leftNavigation = <LeftNavBar />,
  children,
  footer,
  contentTitle,
  className,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.appBar}>{appBar}</div>
      <div className={classes.centerContainer}>
        <div className={classes.leftNavbar}>{leftNavigation}</div>
        <div className={classNames(classes.contentContainer, className)}>
          <div className={classes.contentTitle}>{contentTitle}</div>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      {footer && <div className={classes.appFooter}>{footer}</div>}
    </div>
  )
}
