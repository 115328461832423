import React, { useEffect, useLayoutEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import { Client } from '../../redux/reducers/clients'
import { ArrowSelect } from '../OrderInputDialog/ArrowSelect'
import { Security } from '../../redux/reducers/securities'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { newOrder } from '../../constants/initialStates'
import {
  ORDER_DIRECTION,
  ORDER_MARKET_TYPE,
  ORDER_MARKET_TYPE_LIMIT,
  ORDER_MARKET_TYPE_MARKET,
  ORDER_TYPE,
  ORDER_TYPE_AMOUNT,
  ORDER_TYPE_QUANTITY,
  ORDER_VALIDITY,
  ORDER_VALIDITY_DATE,
} from '../../constants/codeLists'
import { codeListToArrowSelect } from '../../utils/codeListToArrowSelect'
import manS from '../../assets/manS.jpg'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { Order } from '../../redux/reducers/orders'
import { Portfolio } from '../../redux/reducers/portfolio'
import { NumericInput } from '../common/NumericInput'
import NumberFormat from 'react-number-format'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useInputDialogColor } from '../../hooks/useInputDialogColor'
import { useTranslation } from 'react-i18next'
import { useOrderCoveredColor } from '../../hooks/useOrderCoveredColor'
import { useExchangeRate } from '../../hooks/useExchangeRate'
import { useStyles } from './OrderInputDialog.styles'
import { useOrderCoveredColorText } from '../../hooks/useOrderCoveredColorText'
import { 
  ORDER_STATE_NEW, 
} from '../../constants/codeLists'

interface Props {
  client: Client
}

export const OrderInputDialog: React.FC<Props> = ({ client }) => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { postOrder, getCodeList, codeList } = useInstrumentApi()
  const foundDialog = dialogs.find(dialog => dialog.id === 'ORDER_INPUT_DIALOG_ID')
  const open = !!foundDialog
  const security: Security | undefined = useSelector(state => state.securities.security)
  const [order, setOrder] = useState<Order>(newOrder)
  const [isCovered, setIsCovered] = useState(true)
  const [covered, setCovered] = useState(0)
  const instrumentIconType = useInstrumentIconType(!security ? null : security?.assetClass)
  const orderPortfolio: Portfolio = useSelector(state => state.detailPortfolioState.portfolio)
  const getInputDialogColor = useInputDialogColor()
  const rates = useSelector(state => state.currency.rates)
  const { t } = useTranslation()
  const orderCoveredColor = useOrderCoveredColor()
  const orderCoveredColorText = useOrderCoveredColorText()
  const exchangeRate = useExchangeRate()

  useEffect(() => {
    getCodeList()
  }, [])

  useEffect(() => {
    security &&
      setOrder({
        ...order,
        cpty: { ...order.cpty, marketName: security.market, name: `${order.cpty.brokerName} - ${security.market}` },
        state: ORDER_STATE_NEW,
        validityDate: new Date(),
        currency: security.currency,
        securityId: security.securityId,
        price: security.lastQuotePrice,
        portfolio: {
          ...order.portfolio,
          portfolioId: orderPortfolio.portfolioId,
          currency: orderPortfolio.currency,
          name: orderPortfolio.name,
        },
      })
  }, [security])

  useEffect(() => {
    setOrder(newOrder)
    console.log(order, 'ORDER')
  }, [open])

  useEffect(() => {
    cover()
  }, [order.amount])

  useEffect(() => {
    cover()
  }, [order.direction])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
      console.log('closeOrderInputOnLocationChangeFired')
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
  }

  const handleSave = () => {
    postOrder(order)
  }

  const handleChangeArrowSelect = (name: string) => (value: string | number) => {
    setOrder({ ...order, [name]: value })
  }

  const handleChangeOrder = (name: string) => (event: any) => {
    setOrder({ ...order, [name]: event.target.value })
  }

  const handleDateChange = (date: Date | null) => {
    setOrder({ ...order, validityDate: date })
  }

  const onClientInputChange = (client: Client) => (event: any) => {
    let quantity = 0
    let amount = 0
    if (order.type === ORDER_TYPE_QUANTITY) {
      quantity = event.target.value
      amount = Math.round(((Number(quantity) || 0) * (order?.price || 0) + Number.EPSILON) * 100) / 100
    }
    if (order.type === ORDER_TYPE_AMOUNT) {
      amount = event.target.value
      quantity = Math.round(((Number(amount) || 0) / (order?.price || 0) + Number.EPSILON) * 1000000) / 1000000
    }

    setOrder({ ...order, amount: amount, quantity: quantity })
  }

  const cover = () => {
    if (!security) return
    const cover =
      ((client.portfolios[0].accountsBalance || 0) /
        (exchangeRate(security.currency, client.portfolios[0].currency, order.amount || 0, rates) * 1.05)) *
      100
    console.log(
      'COVER CALC VALUES',
      client.portfolios[0].accountsBalance,
      exchangeRate(security.currency, client.portfolios[0].currency, order.amount || 0, rates),
      rates
    )
    if (order.direction === 'OD_SELL') {
      setIsCovered(true)
      setCovered(100)
    } else if (cover < 100) {
      setIsCovered(false)
      setCovered(cover)
      console.log(cover)
    } else if (cover >= 100) {
      setIsCovered(true)
      setCovered(100)
      console.log(cover)
    } else if (cover < 0) {
      setIsCovered(false)
      setCovered(0)
    }
  }

  if (!security || !codeList) return null

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={`${classes.clientInformationDialogHeader} ${getInputDialogColor(order.direction)}`}>
          <Typography variant="h5">Nový Pokyn</Typography>
        </div>
        <DialogContent>
          <Paper variant="outlined" className={classes.clientContainer}>
            <div className={classes.clientPersonalInfoContainer}>
              <div className={`${classes.flexRow}`}>
                <img alt="" className={classes.clientIcon} src={manS} width={20} />
                <div className={classes.clientCredentials}>
                  <span>
                    {client.firstName} <span className={classes.boldText}>{client.surname}</span>
                  </span>
                  <span className={classes.clientPhone}>
                    <NumberFormat value={client.telephoneNumber} displayType={'text'} format="##### ### ### ###" />
                  </span>
                </div>
              </div>
              <div className={`${classes.flexColumn} ${classes.flexGrow}`}>
                <div className={classes.topLineSeparator}></div>
                <div className={classes.flexRow}>
                  <span>Objem portfolia: </span>
                  <span className={` ${classes.alignSelfToEnd} ${classes.flexGrow} ${classes.textToEnd}`}>
                    <NumberFormat
                      value={client.portfolios[0].amount}
                      displayType="text"
                      thousandSeparator=" "
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {client.portfolios[0].currency}
                  </span>
                </div>
                <div className={classes.flexRow}>
                  <span>Zůstatek na účtech: </span>
                  <span
                    className={`${classes.boldText} ${classes.alignSelfToEnd} ${classes.flexGrow} ${
                      classes.textToEnd
                    } ${orderCoveredColorText(isCovered)}`}
                  >
                    <NumberFormat
                      value={client.portfolios[0].accountsBalance}
                      displayType="text"
                      thousandSeparator=" "
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {client.portfolios[0].currency}
                  </span>
                </div>
              </div>
            </div>
          </Paper>
          <Paper variant="outlined" className={classes.commodityContainer}>
            <div className={classes.topContainer}>
              <InstrumentIcon
                className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                securityType={security.assetClass}
              />
              <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>{security.name}</span>
              <div className={classes.topLineSeparator} />
              <div className={classes.isin}>
                <span className={` ${classes.boldText} ${classes.textColor}`}>{security.isin}</span>
                <span className={` ${classes.textColor}`}>{t(security.instrumentType)}</span>
              </div>
            </div>
            <div className={classes.bottomContainer}>
              <div className={classes.flexColumn}>
                <span className={`${classes.boldText} ${classes.textSeparatorLeft} ${classes.textColor}`}>
                  <NumberFormat
                    value={security.lastQuotePrice}
                    displayType="text"
                    thousandSeparator=" "
                    decimalScale={8}
                    decimalSeparator=","
                  />{' '}
                  {security.currency}
                </span>
                <span className={`${classes.securityInfo} ${classes.textSeparatorLeft}`}>{security.lastQuoteDate}</span>
              </div>
              <span className={`${classes.percentage} ${classes.textSeparatorLeft}`}>
                {security.lastPriceIndication}
              </span>
              <div className={classes.topLineSeparator} />
              <span className={classes.securityInfo}>{security.market}</span>
            </div>
          </Paper>
          <Paper variant="outlined" className={classes.selectionContainer}>
            <ArrowSelect
              value={t(order.marketType)}
              label="Typ pokynu"
              options={codeListToArrowSelect(ORDER_MARKET_TYPE, codeList)}
              onChange={handleChangeArrowSelect('marketType')}
            />
            {order.marketType === ORDER_MARKET_TYPE_MARKET && (
              <NumericInput
                InputProps={{
                  readOnly: true,
                }}
                label="Cena"
                fullWidth={true}
                value={security.lastQuotePrice}
              />
            )}
            {order.marketType === ORDER_MARKET_TYPE_LIMIT && (
              <NumericInput
                fullWidth={true}
                value={order.price || ''}
                onChange={handleChangeOrder('price')}
                label="Cena"
              />
            )}
            <ArrowSelect
              value={order.currency}
              label="Měna pokynu"
              options={[
                { label: security.currency, value: security.currency },
                { label: client.portfolios[0].currency, value: client.portfolios[0].currency },
              ]}
              onChange={handleChangeArrowSelect('currency')}
            />
            <ArrowSelect
              value={t(order.direction)}
              label="Směr pokynu"
              options={codeListToArrowSelect(ORDER_DIRECTION, codeList)}
              onChange={handleChangeArrowSelect('direction')}
            />
            <ArrowSelect
              value={t(order.type)}
              label="Způsob pokynu"
              options={codeListToArrowSelect(ORDER_TYPE, codeList)}
              onChange={handleChangeArrowSelect('type')}
            />
            {order.type === ORDER_TYPE_AMOUNT && (
              <NumericInput
                fullWidth={true}
                value={order.amount || ''}
                onChange={onClientInputChange(client)}
                label="Objem obchodu"
              />
            )}
            {order.type === ORDER_TYPE_QUANTITY && (
              <NumericInput
                fullWidth={true}
                value={order.quantity || ''}
                onChange={onClientInputChange(client)}
                label="Počet kusů"
              />
            )}
            <ArrowSelect
              value={t(order.validityType)}
              label="Platnost pokynu"
              options={codeListToArrowSelect(ORDER_VALIDITY, codeList)}
              onChange={handleChangeArrowSelect('validityType')}
            />
            {order.validityType === ORDER_VALIDITY_DATE && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  helperText={false}
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Datum"
                  value={order.validityDate || new Date()}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            {/*}   <ArrowSelect value={textFieldValue} label="Protistrana" options={textFieldValues} /> {*/}
          </Paper>
          <Paper
            variant="outlined"
            className={`${orderCoveredColor(isCovered)} ${classes.currencyTotal} ${classes.textWhite}`}
          >
            <div className={`${classes.flexRow}`}>
              {order.type === ORDER_TYPE_AMOUNT && (
                <div className={`${classes.halfWidth}`}>
                  <div>Počet kusů</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      value={order.quantity || 0}
                      displayType="text"
                      thousandSeparator=" "
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                    />
                  </div>
                </div>
              )}
              {order.type === ORDER_TYPE_QUANTITY && (
                <div className={`${classes.halfWidth}`}>
                  <div>Objem</div>
                  <div className={classes.boldText}>
                    <NumberFormat
                      value={exchangeRate(security.currency, order.currency, order.amount || 0, rates)}
                      displayType="text"
                      thousandSeparator=" "
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                    />{' '}
                    {order.currency}
                  </div>
                </div>
              )}
              <div className={`${classes.halfWidth}`}>
                <div>Krytí</div>
                <div className={classes.boldText}>
                  <NumberFormat
                    value={covered || 0}
                    displayType="text"
                    thousandSeparator=" "
                    fixedDecimalScale
                    decimalScale={0}
                    decimalSeparator=","
                  />
                  %
                </div>
              </div>
            </div>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Storno
          </Button>
          <Button
            className={`${classes.actionButtons} ${getInputDialogColor(order.direction)}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {order.direction === 'OD_BUY' ? 'Nakoupit' : 'Prodat'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
