import { useSelector } from '../redux/hooks/useSelector'
import { PortfolioAsset, Position } from '../redux/reducers/portfolio'

export const useSortPortfolios = () => {
  const portfolioAssets = useSelector(state => state.detailPortfolioState.portfolio.positions)

  const portfolioAssetsByClass: Record<string, Array<Position>> = {}
  portfolioAssets.forEach(portfolio => {
    if (!portfolioAssetsByClass[portfolio.instrument.assetClass]) {
      portfolioAssetsByClass[portfolio.instrument.assetClass] = []
    }
    portfolioAssetsByClass[portfolio.instrument.assetClass].push(portfolio)
  })

  const portfolioAssetsByInstrumentType: Record<string, Array<Position>> = {}
  portfolioAssets.forEach(portfolio => {
    if (!portfolioAssetsByInstrumentType[portfolio.instrument.instrumentType]) {
      portfolioAssetsByInstrumentType[portfolio.instrument.instrumentType] = []
    }
    portfolioAssetsByInstrumentType[portfolio.instrument.instrumentType].push(portfolio)
  })

  const portfolioAssetsByCurrency: Record<string, Array<Position>> = {}
  portfolioAssets.forEach(portfolio => {
    if (!portfolioAssetsByCurrency[portfolio.instrument.currency]) {
      portfolioAssetsByCurrency[portfolio.instrument.currency] = []
    }
    portfolioAssetsByCurrency[portfolio.instrument.currency].push(portfolio)
  })

  return {
    portfolioAssetsByClass,
    portfolioAssetsByInstrumentType,
    portfolioAssetsByCurrency,
  }
}
