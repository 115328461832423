import { makeStyles, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@material-ui/icons/Add'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { Security } from '../../redux/reducers/securities'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useProfitLossSign } from '../../hooks/useProfitLossSign'

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  boldText: {
    fontWeight: 'bold',
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(0.4),
  },
  separator: {
    flexGrow: 1,
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
  separatorLeftHuge: {
    marginLeft: theme.spacing(10),
  },
  separatorLeftLarge: {
    marginLeft: theme.spacing(5),
  },
  borderColorShare: {
    borderColor: theme.palette.error.main,
  },
  borderColorFcMarket: {
    borderColor: theme.palette.success.main,
  },
  borderColorBond: {
    borderColor: theme.palette.primary.main,
  },
  borderColorOther: {
    borderColor: theme.palette.grey[600],
  },
  textColorGreen: {
    color: theme.palette.success.main,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '320px',
    minWidth: '200px',
  },
  instrumentTableButton: {
    height: '15px',
    width: '15px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.2),
  },
  buttonIcon: {
    height: '12px',
    width: '12px',
  },
  iconSize: {
    height: '20px',
    width: '30px',
    lineHeight: '15px',
    fontSize: '8px',
    marginTop: theme.spacing(0.2),
  },
  market: {
    width: '100px',
  },
}))

interface Props {
  security: Security
  style?: React.CSSProperties | undefined
}

export const InstrumentTableRow: React.FC<Props> = ({ security, style }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const instrumentIconType = useInstrumentIconType(security.assetClass)
  const { getSecurity } = useInstrumentApi()
  const getProfitLossSign = useProfitLossSign()

  return (
    <div style={style} className={classes.tableRow}>
      <InstrumentIcon className={`${classes.iconSize} ${instrumentIconType}`} securityType={security.assetClass} />
      <Tooltip title={`${security.isin} ${security.name}`} placement="bottom" enterDelay={500}>
        <Typography className={`${classes.textColor}  ${classes.separatorLeft} ${classes.name}`}>
          {security.name}
        </Typography>
      </Tooltip>
      <Typography className={`${classes.textColor}  ${classes.separatorLeft} ${classes.market}`}>
        {security.market}
      </Typography>
      <Typography style={{ width: 30 }} className={`${classes.textColor}  ${classes.separatorLeft}`}>
        {security.currency}
      </Typography>
      <Typography className={`${classes.textColor} ${classes.separatorLeftLarge}`}>
        {security.lastQuotePrice}
      </Typography>
      <div className={classes.separator}></div>
      <div style={{ width: 20 }}>{getProfitLossSign(security.lastPriceIndication)}</div>
      <Typography className={`${classes.textColor} ${classes.separatorLeftSmall}`}>
        {security.lastPriceIndication}%
      </Typography>
      <Typography className={`${classes.textColor} ${classes.separatorLeftSmall}`}>{security.lastQuoteDate}</Typography>
      <ColoredIconButton
        className={`${classes.instrumentTableButton} ${classes.separatorLeft}`}
        size="small"
        icon={<AddIcon fontSize="small" className={classes.buttonIcon} />}
        onClick={() => {
          dispatch(openDialog(ORDER_INPUT_DIALOG_ID))
          getSecurity(security.securityId)
        }}
      />
    </div>
  )
}
