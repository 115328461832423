import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { ClientDisplay } from '../components/ClientList/ClientDisplay'
import { ClientTableTopBar } from '../components/ClientList/ClientTableTopBar'
import { ActiveOrdersTiles } from '../components/common/ActiveOrdersTiles'
import { ContentTitle } from '../components/common/ContentTitle'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { ClientInformationDialog } from '../components/dialogs/ClientInformationDialog'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { MassOrderInputDialog } from '../components/dialogs/MassOrderInputDialog'
import { BasicLayout } from '../components/layouts/BasicLayout'

const useStyles = makeStyles(theme => ({
  clientDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    flexGrow: 1,
  },
}))

export const ClientList = () => {
  const classes = useStyles()

  return (
    <BasicLayout contentTitle={<ContentTitle contentTitle="Přehled klientů" />}>
      <ActiveOrdersTiles />
      <Paper className={classes.clientDisplayTable}>
        <ClientTableTopBar />
        <ClientDisplay />
      </Paper>
      <ClientInformationDialog />
      <MassOrderInputDialog />
      <FileImportDialog />
    </BasicLayout>
  )
}
