import { Theme } from '@material-ui/core'
import { usePortfolioTotals } from './usePortfolioTotals'

export const useCreateDonutChartSeries = () => {
  const { together } = usePortfolioTotals()

  const donutSeriesValues = <number[]>[]
  const donutSeriesLabels = <string[]>[]
  const donutSeriesColors = <any[]>[]

  const sortedTogether = together.sort((a, b) => {
    if (a[0] === b[0]) {
      return 0
    } else {
      return a[0] > b[0] ? -1 : 1
    }
  })

  sortedTogether.forEach(e => {
    donutSeriesValues.push(e[0] as number)
    donutSeriesLabels.push(e[1] as string)
    donutSeriesColors.push(e[2] as any)
  })

  return {
    donutSeriesValues,
    donutSeriesLabels,
    donutSeriesColors,
    sortedTogether,
  }
}
