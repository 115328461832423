import { makeStyles } from '@material-ui/core'
import React from 'react'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const useStyles: any = makeStyles(theme => ({
  orangeIcon: {
    color: theme.palette.error.main,
    fontSize: 14,
    marginTop: '4px',
  },
  greenIcon: {
    color: theme.palette.success.main,
    fontSize: 14,
    marginTop: '4px',
  },
}))

export const useProfitLossSign = () => {
  const classes = useStyles()

  return (value: number) => {
    if (value > 0) {
      return (
        <span style={{ color: 'green' }}>
          <ArrowUpwardIcon className={classes.greenIcon} />
        </span>
      )
    } else if (value < 0) {
      return (
        <span>
          <ArrowDownwardIcon className={classes.orangeIcon} />
        </span>
      )
    }
    return null
  }
}
