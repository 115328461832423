import { makeStyles, Paper } from '@material-ui/core'
import React, { useLayoutEffect } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ActiveOrdersTiles } from '../components/common/ActiveOrdersTiles'
import { ContentTitle } from '../components/common/ContentTitle'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { MassOrderInputDialog } from '../components/dialogs/MassOrderInputDialog'
import { BasicLayout } from '../components/layouts/BasicLayout'
import { OrderDisplay } from '../components/OrderList/OrderDisplay'
import { OrderTableTopBar } from '../components/OrderList/OrderTableTopBar'
import { emptyClient } from '../constants/initialStates'
import { useOrderFilters } from '../hooks/useOrderFilters'
import { getClientAction } from '../redux/actions/clientActions'

const useStyles = makeStyles(theme => ({
  orderDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    flexGrow: 1,
  },
}))

export const OrderList = () => {
  const classes = useStyles()
  const orderTableRows = useOrderFilters()
  const dispatch = useDispatch()

  useEffect(() => {}, [orderTableRows])

  useLayoutEffect(() => {
    return () => {
      dispatch(getClientAction(emptyClient))
    }
  }, [])

  return (
    <BasicLayout contentTitle={<ContentTitle contentTitle="Přehled pokynů" />}>
      <ActiveOrdersTiles />
      <Paper className={classes.orderDisplayTable}>
        <OrderTableTopBar />
        <OrderDisplay />
      </Paper>
      <MassOrderInputDialog />
      <FileImportDialog />
      <ActionAlert />
      <ActionFailedAlert />
    </BasicLayout>
  )
}
