import React, { useLayoutEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { ACTION_FAILED_ALERT_ID, ACTION_SUCESS_ALERT_ID } from '../../constants/dialogConstants'
import { closeDialog } from '../../redux/actions/dialogActions'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({}))

export const ActionAlert = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const foundDialog = dialogs.find(dialog => dialog.id === 'ACTION_SUCESS_ALERT_ID')
  const open = !!foundDialog

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ACTION_SUCESS_ALERT_ID))
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(ACTION_SUCESS_ALERT_ID))
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Akce se provedla</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request proběhl úspěšně a vrací zprávu o stavu:
            <br />
            <br />
            {foundDialog?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
