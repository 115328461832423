import { makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { filterInstrumentName, filterMarketName } from '../../redux/actions/filterActions'
import { useSelector } from '../../redux/hooks/useSelector'

const useStyles = makeStyles(theme => ({
  filterContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconFilter: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
  },
  iconSize: {
    height: '15px',
    width: '23px',
  },
  nameFilter: {
    width: '80px',
    height: '30px',
    marginLeft: theme.spacing(0.5),
  },
}))

interface Props {}

export const InstrumentTableFilters: React.FC<Props> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)
  const { getInstrumentsByParam } = useInstrumentApi()

  const onNameSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterInstrumentName(event.target.value))
    if (event.target.value.length >= 3) {
      getInstrumentsByParam(event.target.value)
    } else if (event.target.value.length === 0) {
      getInstrumentsByParam('')
    }
  }

  const onMarketSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterMarketName(event.target.value))
  }

  return (
    <div className={classes.filterContainer}>
      <div className={classes.nameFilter}>
        <TextField
          className={classes.nameFilter}
          placeholder="Instrument"
          onChange={onNameSearchFieldChange}
          value={filters.searchedInstrumentName}
        />
      </div>
      <div className={classes.nameFilter}>
        <TextField
          className={classes.nameFilter}
          placeholder="Market"
          onChange={onMarketSearchFieldChange}
          value={filters.searchedMarketName}
        />
      </div>
    </div>
  )
}
