import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { useSelector } from '../redux/hooks/useSelector'
import { OrdersByStateOptions } from '../redux/reducers/filters'
import { OrderTableRow } from '../redux/reducers/orders'
import { Order } from '../redux/reducers/orders'
import { RootState } from '../redux/reducers/root'
import { 
  ORDER_STATE_NEW, 
  ORDER_STATE_CANCELED, 
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_FILLED,
  ORDER_STATE_EXPIRED,
  ORDER_STATE_REJECTED,
  ORDER_STATE_PARTIAL_FILLED,
  ORDER_STATE_PARTIAL_EXPIRED,
  ORDER_STATE_PARTIAL_CANCELED,
} from '../constants/codeLists'


export const useOrderFilters = () => {
  const orderTableRows: OrderTableRow[] = useSelector((state: RootState) => state.orders.orderTableRows)
  const filters = useSelector((state: RootState) => state.filters)

  let filteredTableRows = orderTableRows.filter(
    (order: OrderTableRow) =>
      `${order.clientFirstname}${order.clientSurname}`
        .toLowerCase()
        .includes(filters.searchedClientName.toLowerCase()) &&
      (order.amount || 0) >= filters.searchedAmountUp &&
      (order.amount || 0) <= (filters.searchedAmountDown === '' ? Infinity : filters.searchedAmountDown) &&
      `${order.securityName}`.toLowerCase().includes(filters.searchedInstrumentName.toLowerCase()) &&
      `${order.currency}`.toLowerCase().includes(filters.searchedCurrencyName.toLowerCase())
  )

  switch (filters.sortOrdersBy) {
    case OrdersByStateOptions.New:
      filteredTableRows = orderTableRows.filter(row => row.state === ORDER_STATE_NEW)
      break
    case OrdersByStateOptions.Open:
      filteredTableRows = orderTableRows.filter(row => row.state === ORDER_STATE_CONFIRMED)
      break
    case OrdersByStateOptions.Partial:
      filteredTableRows = orderTableRows.filter(row => row.state === ORDER_STATE_PARTIAL_FILLED)
      break
    case OrdersByStateOptions.Rejected:
      filteredTableRows = orderTableRows.filter(row => row.state === ORDER_STATE_REJECTED)
      break
    case OrdersByStateOptions.Closed:
      filteredTableRows = orderTableRows.filter(row => row.state === ORDER_STATE_FILLED ||
         row.state === ORDER_STATE_CANCELED || row.state === ORDER_STATE_PARTIAL_EXPIRED || 
         row.state === ORDER_STATE_PARTIAL_CANCELED || row.state === ORDER_STATE_EXPIRED)
      break
  }
  return filteredTableRows
}
