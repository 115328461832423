import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useSelector } from '../../redux/hooks/useSelector'
import { OrderAction } from '../../redux/reducers/orders'
import { ACTION_BROKER_SEND_TO_PARTIAL_FILL, ACTION_BROKER_SEND_TO_FILL } from '../../constants/codeLists'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

const useStyles = makeStyles({
  table: {
    minWidth: '40vw',
  },
  fillTableContainer: {
    minHeight: '60vh',
  },
  fillCell: {},
  actionCell: {
    fontStyle: 'italic',
  },
  filterSwitch: {
    marginLeft: '5px',
    marginTop: '5px',
  },
})

export const FillTable = () => {
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles()
  const order = useSelector(state => state.orders.order)
  const { t } = useTranslation()

  const isFill = (code: string | null) => (code === ACTION_BROKER_SEND_TO_PARTIAL_FILL ||
    code === ACTION_BROKER_SEND_TO_FILL)

  return (
    <>
      <TableContainer component={Paper} className={classes.fillTableContainer}>
        <Table size="small" stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fill</TableCell>
              <TableCell align="right">Datum</TableCell>
              <TableCell align="right">Typ</TableCell>
              <TableCell align="right">Počet KS</TableCell>
              <TableCell align="right">Cena</TableCell>
              <TableCell align="right">Trh</TableCell>
              <TableCell align="right">Měna</TableCell>
              <TableCell align="right">Poplatek</TableCell>
              <TableCell align="right">Objem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.actions.filter((e: OrderAction) => showAll || isFill(e.code))
              .map((row: OrderAction, i: Number) => (
                <TableRow key={(row.extenalId || "ID") + "-" + i}>
                  <TableCell component="th" scope="row">
                    {row.extenalId}
                  </TableCell>
                  <TableCell align="right">{row.date && format(new Date(row.date), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                  <TableCell align="right"
                    className={isFill(row.code) ? classes.fillCell : classes.actionCell}>
                    {row.code ? t(row.code) : ''}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">
                    {row.price &&
                      <NumberFormat
                        value={row.price}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />}
                  </TableCell>
                  <TableCell align="right">{row.market}</TableCell>
                  <TableCell align="right">{row.currency}</TableCell>
                  <TableCell align="right">
                    {row.fee &&
                      <NumberFormat
                        value={row.fee}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />}
                  </TableCell>
                  <TableCell align="right">
                    {row.amount &&
                      <NumberFormat
                        value={row.amount}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FormControlLabel
        className={classes.filterSwitch}
        control={
          <Switch
            checked={showAll}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setShowAll(checked)}
            name="showAll"
            color="primary"
            size="small"
          />
        }
        label="Zobrazit včetně akcí"
      />
    </>
  )
}
