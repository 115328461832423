import { AppBar, makeStyles, TextField, Toolbar } from '@material-ui/core'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { NumericInput } from '../common/NumericInput'
import { filters, OrdersByStateOptions } from '../../redux/reducers/filters'
import { sortOrderTable } from '../../redux/actions/filterActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../redux/hooks/useSelector'

const useStyles = makeStyles(theme => ({
  tableTopBar: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    maxHeight: '50px',
    padding: theme.spacing(1),
  },
  tableToolBar: {
    minHeight: 0,
    display: 'flex',
    width: '100%',
  },
  separator: {
    flexGrow: 1,
  },
  textField: {
    width: '100px',
    marginRight: theme.spacing(1),
  },
  datePicker: {
    marginRight: theme.spacing(1),
  },
}))

export const OrderTableTopBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.filters)

  const onSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(sortOrderTable(event.target.value as OrdersByStateOptions))
  }

  return (
    <AppBar color="secondary" position="static" className={classes.tableTopBar}>
      <Toolbar variant="dense" className={classes.tableToolBar}>
        <TextField className={classes.textField} placeholder="Klient" />
        <TextField className={classes.textField} placeholder="Typ" />
        <NumericInput className={classes.textField} placeholder="Objem od" />
        <NumericInput className={classes.textField} placeholder="Objem do" />
        <TextField className={classes.textField} placeholder="Instrument" />
        <TextField className={classes.textField} placeholder="Měna" />
        <Select value={filters.sortOrdersBy} onChange={onSortByChange}>
          <MenuItem value={OrdersByStateOptions.Rejected}>Odmítnuté</MenuItem>
          <MenuItem value={OrdersByStateOptions.Open}>Otevřené</MenuItem>
          <MenuItem value={OrdersByStateOptions.Partial}>Částečné</MenuItem>
          <MenuItem value={OrdersByStateOptions.New}>Nové</MenuItem>
          <MenuItem value={OrdersByStateOptions.All}>Vše</MenuItem>
          <MenuItem value={OrdersByStateOptions.Closed}>Uzavřené</MenuItem>
        </Select>
      </Toolbar>
    </AppBar>
  )
}
