import React, { ReactNode } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
}))

interface Props {
  contentTitle: string
  actionButtons?: ReactNode
}

export const ContentTitle: React.FC<Props> = ({ contentTitle, actionButtons }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.root}>
      {contentTitle}
      {actionButtons}
    </Typography>
  )
}
