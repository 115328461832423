import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Button } from '@material-ui/core'
import { PropTypes } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
}))

interface Props {
  onClick?: () => void
  size?: 'small' | 'medium' | undefined
  className?: string
  content: any
  disabled?: boolean
  color?: PropTypes.Color
}

export const TransparentButton: React.FC<Props> = ({ onClick, size, className, content, disabled, color }) => {
  const classes = useStyles()

  return (
    <Button
      color={color}
      className={classNames(classes.root, className)}
      onClick={onClick}
      size={size}
      disabled={disabled}
    >
      {content}
    </Button>
  )
}
