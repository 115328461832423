import { AppBar, makeStyles, TextField, Toolbar, Typography } from '@material-ui/core'
import React from 'react'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import {
  filterClientAmountDown,
  filterClientAmountUp,
  filterClientByInstrumentName,
  fliterClientName,
  sortClients,
} from '../../redux/actions/filterActions'
import { ClientSortOptions } from '../../redux/reducers/filters'
import { NumericInput } from '../common/NumericInput'
import { getClientsByParamRequest } from '../../api/clientAPI'
import { useClientApi } from '../../hooks/useClientApi'

const useStyles = makeStyles(theme => ({
  tableTopBar: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    maxHeight: '50px',
    padding: theme.spacing(1),
  },
  tableToolBar: {
    minHeight: 0,
    display: 'flex',
    width: '100%',
  },
  separator: {
    flexGrow: 1,
  },
  textField: {
    width: '100px',
    marginRight: theme.spacing(1),
  },
  datePicker: {
    marginRight: theme.spacing(1),
  },
}))

export const ClientTableTopBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getClientsByParam } = useClientApi()

  const filters = useSelector(state => state.filters)

  const onSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(sortClients(event.target.value as ClientSortOptions))
  }

  const onNameSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(fliterClientName(event.target.value))
  }

  const onInstrumentSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    getClientsByParam(event.target.value)
  }

  const onAmountUpSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountUp(event.target.value))
  }

  const onAmountDownSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountDown(event.target.value))
  }

  return (
    <AppBar color="secondary" position="static" className={classes.tableTopBar}>
      <Toolbar variant="dense" className={classes.tableToolBar}>
        <TextField
          className={classes.textField}
          placeholder="Klient"
          onChange={onNameSearchFieldChange}
          value={filters.searchedClientName}
        />
        <NumericInput
          className={classes.textField}
          placeholder="Objem od"
          onChange={onAmountUpSearchFieldChange}
          value={filters.searchedAmountUp}
        />
        <NumericInput
          className={classes.textField}
          placeholder="Objem do"
          onChange={onAmountDownSearchFieldChange}
          value={filters.searchedAmountDown}
        />
        <TextField className={classes.textField} placeholder="Instrument" onChange={onInstrumentSearchFieldChange} />
        <Select value={filters.sortBy} onChange={onSortByChange}>
          <MenuItem value={ClientSortOptions.Az}>Jméno A-Z</MenuItem>
          <MenuItem value={ClientSortOptions.Za}>Jméno Z-A</MenuItem>
          <MenuItem value={ClientSortOptions.AmountDown}>Investovaný objem sestupně</MenuItem>
          <MenuItem value={ClientSortOptions.AmountUp}>Investovaný objem vzestupně</MenuItem>
        </Select>
        <Typography className={classes.separator} />
        <ColoredIconButton
          icon={<AddIcon />}
          size="small"
          onClick={() => {
            dispatch(openDialog(CLIENT_INFORMATION_DIALOG_ID, { dialogTitle: 'Nový klient', type: 'new' }))
          }}
        />
      </Toolbar>
    </AppBar>
  )
}
