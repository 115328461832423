import { Grid, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useClientApi } from '../../hooks/useClientApi'
import { useClientFilters } from '../../hooks/useClientFilters'
import { ClientTile } from './ClientTile'

const useStyles = makeStyles(theme => ({
  clientTable: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(1),
    maxHeight: 'calc(100vh - 320px);',
    overflow: 'auto',
  },
  clientTile: {},
}))

export const ClientDisplay = () => {
  const classes = useStyles()
  const clients = useClientFilters()
  const { getClients } = useClientApi()

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {}, [clients])

  return (
    <div className={classes.clientTable}>
      <Grid container spacing={2}>
        {clients.map((client, index) => (
          <Grid className={classes.clientTile} key={client.clientId} item xs={4} sm={3} md={3} lg={2} xl={2}>
            <ClientTile client={client} key={index} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
