import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

interface Props {
  alertOpen: boolean
  onOk: () => void
  onCancel: () => void
  alertText: string
}

export const ClearConfirmationAlert: React.FC<Props> = ({ alertOpen, onOk, onCancel, alertText }) => {
  return (
    <div>
      <Dialog open={alertOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Opravdu si přejete {alertText}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            Storno
          </Button>
          <Button color="primary" autoFocus onClick={onOk}>
            Potvrdit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
