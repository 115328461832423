import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { ColoredIconButton } from '../common/ColoredIconButton'
import EditIcon from '@material-ui/icons/Edit'
import { useDispatch } from 'react-redux'
import { ORDER_DETAIL_DIALOG_ID } from '../../constants/dialogConstants'
import { openDialog } from '../../redux/actions/dialogActions'
import { OrderEditDialog } from '../dialogs/OrderEditDialog'
import { OrderDetailDialog } from '../dialogs/OrderDetailDialog'
import NumberFormat from 'react-number-format'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useOrderFilters } from '../../hooks/useOrderFilters'
import { useOrderTableRowColor } from '../../hooks/useOrderTableRowColor'
import { useZebraStyle } from '../../hooks/useZebraStyle'
import { useBackgroundColor } from '../../hooks/usePercentColor'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  editButton: {},
  iconSize: {
    height: '20px',
    width: '40px',
    lineHeight: '18px',
    fontSize: '10px',
  },
  columnCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  largerFont: {
    fontSize: '14px',
  },
  backgroundTest: {
    backgroundColor: 'black',
  },
  bordertest: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
  },
}))

function createData(
  surname: string,
  firstName: string,
  amount: number,
  instrumentType: string,
  security: string,
  currency: string,
  state: string,
  clientId: number,
  securityId: number,
  orderId: number,
  direction: string,
  orderIdent: string,
  orderCreationDate: Date,
  validityType: string,
  validityDate: string,
  price: number,
  quantity: number,
  isin: string,
  symbol: string,
  ticker: string,
  orderMarketType: string,
  assetClass: string
) {
  return {
    surname,
    firstName,
    amount,
    instrumentType,
    security,
    currency,
    state,
    clientId,
    securityId,
    orderId,
    direction,
    orderIdent,
    orderCreationDate,
    validityType,
    validityDate,
    price,
    quantity,
    isin,
    symbol,
    ticker,
    orderMarketType,
    assetClass,
  }
}

export const OrderTable = () => {
  const classes = useStyles()
  const { getOrderTableRows } = useInstrumentApi()
  const orderTableRows = useOrderFilters()
  const dispatch = useDispatch()
  const selectedState = useSelector(state => state.filters.sortOrdersBy)
  const backgroundRowColor = useOrderTableRowColor()
  const zebraStyle = useZebraStyle()
  const { t } = useTranslation()

  useEffect(() => {
    getOrderTableRows(selectedState)
  }, [getOrderTableRows, selectedState])

  const rows = orderTableRows.map(row =>
    createData(
      row.clientSurname,
      row.clientFirstname,
      row.amount,
      row.instrumentType,
      row.securityName,
      row.currency,
      row.state,
      row.clientId,
      row.securityId,
      row.orderId,
      row.direction,
      row.orderIdent,
      row.orderCreationDate,
      row.validityType,
      row.validityDate,
      row.price,
      row.quantity,
      row.isin,
      row.symbol,
      row.ticker,
      row.orderMarketType,
      row.assetClass
    )
  )

  useEffect(() => {}, [rows])

  const orderDirectionValue = (orderDirection: string) => {
    if (orderDirection === 'OD_BUY') {
      return 'Nákup'
    } else {
      return 'Prodej'
    }
  }

  const marketTypeValue = (marketType: string) => {
    if (marketType === 'OMT_LIMIT') {
      return 'Limit'
    } else {
      return 'Trh'
    }
  }

  const orderValidityValue = (orderValidity: string | number) => {
    if (orderValidity === 'OV_GTC') {
      return 'Do zrušení'
    } else {
      return 'Do data'
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader className={`${classes.table}`} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Číslo pokynu</TableCell>
              <TableCell>Datum založení</TableCell>
              <TableCell align="right">Klient</TableCell>
              <TableCell align="right">Směr/Typ pokynu</TableCell>
              <TableCell align="right">Platnost</TableCell>
              <TableCell align="right">Cena/Ks</TableCell>
              <TableCell align="right">Objem/Měna</TableCell>
              <TableCell align="right">Instrument</TableCell>
              <TableCell align="right">Stav</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                className={`${backgroundRowColor(row.direction, index)} ${zebraStyle(index)} ${classes.bordertest}`}
                key={row.orderId}
              >
                <TableCell component="th" scope="row">
                  <InstrumentIcon className={`${classes.iconSize}`} securityType={row.assetClass} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.orderIdent}
                </TableCell>
                <TableCell component="th" scope="row">
                  {format(new Date(row.orderCreationDate), 'dd/MM/yyyy HH:mm')}
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont}`}>{row.surname}</span>
                    <span>{row.firstName}</span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont} ${classes.boldText}`}>
                      {orderDirectionValue(row.direction)}
                    </span>
                    <span>{marketTypeValue(row.orderMarketType)}</span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont}`}>{orderValidityValue(row.validityType)}</span>
                    <span>
                      {row.validityType === 'OV_GTD'
                        ? row.validityDate
                          ? format(new Date(row.validityDate), 'dd/MM/yyyy')
                          : ''
                        : ''}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont}`}>
                      <NumberFormat
                        value={row.price}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    </span>
                    <span>
                      <NumberFormat
                        value={row.quantity}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={4}
                        decimalSeparator=","
                      />{' '}
                      ks
                    </span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont}`}>
                      <NumberFormat
                        value={row.amount}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    </span>
                    <span>{row.currency}</span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.columnCell}>
                    <span className={` ${classes.largerFont}`}>{row.security}</span>
                    <span>
                      {row.isin}
                      {row.symbol ? `/${row.symbol}` : ''}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="right">{t(row.state)}</TableCell>
                <TableCell align="right">
                  <ColoredIconButton
                    className={classes.editButton}
                    size="small"
                    onClick={() =>
                      dispatch(
                        openDialog(ORDER_DETAIL_DIALOG_ID, {
                          clientId: row.clientId,
                          securityId: row.securityId,
                          orderId: row.orderId,
                        })
                      )
                    }
                    icon={<EditIcon fontSize="small" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderEditDialog />
      <OrderDetailDialog />
    </>
  )
}
