import { Button, Paper, Switch, Typography } from '@material-ui/core'
import { ColoredIconButton } from '../common/ColoredIconButton'
import { PortfolioDonutChart } from './PortfolioDonutChart'
import React, { useState } from 'react'
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark'
import { filterPortfolioDetail } from '../../redux/actions/filterActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../redux/hooks/useSelector'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { useStyles } from './ClientPortfolio.styles'
import { PortfolioDetailTablelist } from './PortfolioDetailTablelist'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { showPortfolioDetailAction } from '../../redux/actions/userActions'
import { useEffect } from 'react'
import { PORTFOLIO_OVERVIEW } from '../../constants/textConstants'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

export const ClientPortfolio = () => {
  const classes = useStyles()
  const portfolioDetailFilter = useSelector(state => state.filters.portfolioDetail)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (
      portfolioDetailFilter.class === false &&
      portfolioDetailFilter.currency === false &&
      portfolioDetailFilter.instrumentType === false
    ) {
      dispatch(filterPortfolioDetail('instrumentType', true))
    }
  }, [portfolioDetailFilter])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(filterPortfolioDetail(event.target.name, event.target.checked))
    if (
      portfolioDetailFilter.class === false &&
      portfolioDetailFilter.currency === false &&
      portfolioDetailFilter.instrumentType === false
    ) {
    }
  }

  const onMinimizeButtonClick = () => {
    dispatch(showPortfolioDetailAction(true))
  }

  //TODO Collapsible BOX

  return (
    <Paper variant="outlined" className={classes.portfolioTableContainer}>
      <div className={classes.infoWindowTopBar}>
        <BrandingWatermarkIcon />
        <Typography className={classes.instrumentsTitle}>{t(PORTFOLIO_OVERVIEW)}</Typography>
        <span className={classes.separator} />
        <Button
          classes={{ root: classes.detailButton }}
          size="small"
          endIcon={<ArrowForwardIcon />}
          onClick={onMinimizeButtonClick}
        >
          Detail portfolia
        </Button>
      </div>
      <div className={classes.infoWindowContainer}>
        <div className={classes.infoWindowActions}>
          <FormControlLabel
            control={
              <Switch
                checked={portfolioDetailFilter.instrumentType}
                onChange={handleChange}
                name="instrumentType"
                color="primary"
                size="small"
              />
            }
            label="Typ instrumentu"
          />
          <FormControlLabel
            control={
              <Switch
                checked={portfolioDetailFilter.class}
                onChange={handleChange}
                name="class"
                color="primary"
                size="small"
              />
            }
            label="Třídy aktiv"
          />

          <FormControlLabel
            control={
              <Switch
                checked={portfolioDetailFilter.currency}
                onChange={handleChange}
                name="currency"
                color="primary"
                size="small"
              />
            }
            label="Měna"
          />
        </div>
        <div className={classes.infoWindowData}>
          <div className={classes.chartContainer}>
            <PortfolioDonutChart />
          </div>
          <div className={classes.portfolioDetailContainer}>
            <PortfolioDetailTablelist />
          </div>
        </div>
      </div>
    </Paper>
  )
}
