import { emptyClient } from '../../constants/initialStates'
import { ReduxAction } from '../../types/common'
import {
  ADD_CLIENT,
  GET_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_ACCOUNT_TOTAL,
  GET_CLIENT_ROLES,
  UPDATE_CLIENT,
} from '../actionTypes'

interface Adress {
  addressId?: number | null | undefined
  addressType?: string | null | undefined
  country?: string | null | undefined
  street?: string | null | undefined
  town?: string | null | undefined
  zipCode?: string | null | undefined
}
export interface Client {
  birthDate: Date | string | null
  clientCounterpartyId: string | null
  clientId: number
  portfolios: Array<{
    currency: string
    firstIndicatorDate: null
    lastIndicatorDate: null
    name: string
    portfolioId: number
    amount: number | null
    accountsBalance: number | null
  }>
  contactAddress: Adress | null
  contactAddressEqualsPermanent: boolean
  counterpartyName: string | null
  email: string | null
  firstName: string | null
  identityCardIssuer: string | null
  identityCardNumber: string | null
  identityCardValidity: Date | string | null
  investmentHorizon: string | null
  investmentQuestionnaireValidTo: Date | null | string
  note: string | null
  permanentAddress: Adress
  personalNumber: string | null
  riskProfile: number | null
  state: string | null
  surname: string | null
  telephoneNumber: string | null
  titleAfter: string | null
  titlePre: string | null
  uid: string | null
  nationality: string | null
  roles: string[]
}

export interface MassOrderClient extends Client {
  massOrderByAmount: number | null
  massOrderByQuantity: number | null
  massOrderCurrency: string | null
  coverPercentage: number | null
  exchangedFromTo: boolean
  isCovered: boolean
}

export interface ClientRole {
  name: string
  roleId: number
  parentId: number
}

export interface ClientRolesList {
  roles: ClientRole[]
}

interface ClientState {
  clients: Client[]
  client: Client
  clientRoles: ClientRole[]
  clientAccountTotal: Number | null
}

export const requiredClientAttributes = [
  'surname',
  'personalNumber',
  'identityCardNumber',
  'street',
  'town',
  'zipCode',
  'country',
  'roles',
]

const INITIAL_STATE = {
  clients: [],
  client: emptyClient,
  clientRoles: [],
  clientAccountTotal: null,
}

export const clients = (state: ClientState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload as Client[],
      }
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload as Client,
      }
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload as Client],
      }
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: [...state.clients.filter(c => c.clientId !== action.payload.clientId), action.payload],
        client: action.payload,
      }
    case GET_CLIENT_ROLES:
      return {
        ...state,
        clientRoles: action.payload as ClientRole[],
      }
    case GET_CLIENT_ACCOUNT_TOTAL:
      return {
        ...state,
        clientAccountTotal: action.payload as Number,
      }
    default:
      return state
  }
}
