import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
  },
}))

interface Props {
  onClick?: () => void
  icon?: React.ReactNode
  size?: 'small' | 'medium' | undefined
  className?: string
}

export const TransparentIconButton: React.FC<Props> = ({ onClick, icon, size, className }) => {
  const classes = useStyles()

  return (
    <IconButton className={classNames(classes.root, className)} onClick={onClick} size={size}>
      {icon}
    </IconButton>
  )
}
