import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { InstrumentTableRow } from './InstrumentTableRow'
import { InstrumentTableFilters } from './InstrumentTableFilters'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useInstrumentFilters } from '../../hooks/useInstrumentFilters'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList as List } from 'react-window'
import { AutoSizer } from 'react-virtualized'
import { InstrumentTableHeader } from './InstrumentTableHeader'
import { useSelector } from '../../redux/hooks/useSelector'
import { getSecuritiesAction } from '../../redux/actions/instrumentActions'

const useStyles = makeStyles(theme => ({
  detailTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    justifyContent: 'center',
    height: '100%',
  },
  instrumentTableContent: {
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
}))

const LOADING = 1
const LOADED = 2
let itemStatusMap: Record<string, number> = {}

const isItemLoaded = (index: number) => !!itemStatusMap[index]

const loadMoreItems = (startIndex: number, stopIndex: number) => {
  for (let index = startIndex; index <= stopIndex; index++) {
    itemStatusMap[index] = LOADING
  }
  return new Promise<void>(resolve => {
    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = LOADED
    }
    resolve()
  })
}

interface VirtualRowProps {
  style: React.CSSProperties | undefined
  index: number
  items: any[]
  itemStatusMap: Record<string, number>
}

const VirtualRow: React.FC<VirtualRowProps> = ({ style, index }) => {
  const items = useInstrumentFilters()

  if (itemStatusMap[index] === LOADING) return null
  return <InstrumentTableRow style={style} security={items[index]} key={index} />
}

export const InstrumentTable = () => {
  const classes = useStyles()
  const { getSecurities } = useInstrumentApi()
  const securities = useInstrumentFilters()

  useEffect(() => {
    getSecurities()
  }, [])

  return (
    <div className={classes.detailTableContainer}>
      <div className={classes.instrumentTableContent}>
        <InstrumentTableFilters />
        <InstrumentTableHeader />
        {/*{securities.map((security: any, index: any) => (
          <InstrumentTableRow security={security} key={index} />
        ))}*/}

        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={securities.length} loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  itemCount={securities.length}
                  itemSize={30}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {/* @ts-ignore */}
                  {VirtualRow}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
