import { makeStyles } from '@material-ui/core'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { usePortfolioTotals } from '../../hooks/usePortfolioTotals'

const useStyles = makeStyles(theme => ({
  chartContainer: {
    height: '100%',
    width: '100%',
  },
}))

export const PortfolioDonutChartMini = () => {
  const { donutDataSeries, donutDataLabels, colors } = usePortfolioTotals()
  const classes = useStyles()

  const state = {
    options: {
      stroke: {
        width: 0,
      },
      tooltip: {
        y: {
          formatter: (w: any) => Number(w)?.toLocaleString(),
        },
      },
      plotOptions: {
        stroke: {
          show: false,
        },
        pie: {
          donut: {
            size: '50%',
            labels: {
              show: false,
              total: {
                show: true,
                formatter: function (w: any) {
                  return w.globals.seriesTotals
                    .reduce((a: any, b: any) => {
                      return a + b
                    }, 0)
                    ?.toLocaleString()
                },
              },
              value: {
                show: true,
                fontSize: '11px',
                formatter: function (w: any) {
                  return Number(w)?.toLocaleString()
                },
              },
            },
          },
        },
      },
      colors: colors,
      fill: {
        pattern: {
          strokeWidth: 6,
        },
      },

      series: donutDataSeries,
      labels: donutDataLabels,
      chart: {
        type: 'donut',
        total: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
        formatter: function (w: any) {
          return 'hello'
        },
      },
    },
  }

  return (
    <div id="chart" className={classes.chartContainer}>
      <ReactApexChart width="100%" height="100%" options={state.options} series={state.options.series} type="donut" />
    </div>
  )
}
