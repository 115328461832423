export const ORDER = 'ORDER'
export const INSTRUMENT_NAME = 'INSTRUMENT_NAME'
export const MARKET = 'MARKET'
export const CURRENCY = 'CURRENCY'
export const PORTFOLIO_OVERVIEW = 'PORTFOLIO_OVERVIEW'
export const NAME = 'NAME'
export const PRICE = 'PRICE'
export const MOVEMENT = 'MOVEMENT'
export const DATA_FROM = 'DATA_FROM'
export const ORDER_STATE = 'ORDER_STATE'
export const ORDER_DIRECTION = 'ORDER_DIRECTION'
export const ORDER_AMOUNT = 'ORDER_AMOUNT'
export const ORDER_QUANTITY = 'ORDER_QUANTITY'
